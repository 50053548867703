import React from 'react';
import Header from './Header';
import Footer from './footer';
import './styles/main.css'

const Layout = ({ children }) => {
    return (
        <div className='med-container'>
            <Header />
            <main>{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
