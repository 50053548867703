import React, { useState, useEffect } from 'react';
import './styles/header.css';
import logo from '../logo.png';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { FaCaretDown } from "react-icons/fa";
import { FaBlogger, FaShoppingCart, FaClipboardList } from "react-icons/fa";
import { MdForum } from "react-icons/md";
import { supabase } from '../utils/supabase';

export default function Header() {
    const { user, loginWithRedirect, logout, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
    const baseURL = process.env.REACT_APP_BASE_URL;
    const [userRoles, setUserRoles] = useState([]);
    const [cartCount, setCartCount] = useState(0);  // State to hold cart count
    const [userID, setUserID] = useState(null);

    // Fetch user roles on auth change
    useEffect(() => {
        const fetchUserRoles = async () => {
            if (isAuthenticated) {
                const claims = await getIdTokenClaims();
                const rolesFromToken = claims[baseURL + '/roles'] || [];
                setUserRoles(rolesFromToken);
                setUserID(claims.sub);
            }
        };
        fetchUserRoles();
    }, [isAuthenticated, getIdTokenClaims]);

    // Fetch initial cart count
    useEffect(() => {
        const fetchCartCount = async () => {
            if (userID) {
                const { data, error } = await supabase
                    .from('customer_orders')
                    .select('*', { count: 'exact' })
                    .eq('customer_id', userID)
                    .eq('order_status', 'pending');

                if (error) {
                    console.error('Error fetching cart count: ', error);
                } else {
                    setCartCount(data.length);
                }
            }
        };
        fetchCartCount();
    }, [userID]);

    // Subscribe to real-time updates for cart count
    useEffect(() => {
        if (userID) {
            const subscription = supabase
                .channel('realtime-cart')
                .on(
                    'postgres_changes',
                    { event: '*', schema: 'public', table: 'customer_orders', filter: `customer_id=eq.${userID}` },
                    async (payload) => {
                        // Fetch updated cart count when there is an insert, update, or delete event
                        const { data, error } = await supabase
                            .from('customer_orders')
                            .select('*', { count: 'exact' })
                            .eq('customer_id', userID)
                            .eq('order_status', 'pending');

                        if (!error) {
                            setCartCount(data.length);
                        } else {
                            console.error('Error updating cart count: ', error);
                        }
                    }
                )
                .subscribe();

            // Clean up subscription on component unmount
            return () => {
                supabase.removeChannel(subscription);
            };
        }
    }, [userID]);

    return (
        <div>
            <header>
                <div className="container">

                    <div className="logo-container" style={{ display: !isAuthenticated ? 'flex' : 'none' }}>
                        <Link to="/" className="logo"><img src={logo} alt='Logo' width="75" height="75" /></Link>
                    </div>

                    <div className="nav-btn">
                        <div className="nav-links">
                            <ul>
                                <li className="nav-link">
                                    <Link to="/" >Home</Link>
                                </li>
                                <li className="nav-link">
                                    <Link to="/medi-tracker" >Medicine Tracker</Link>
                                </li>
                                <li className="nav-link">
                                    <Link to="/scheduling">Scheduling</Link>
                                </li>
                                <li className="nav-link">
                                    <Link to="/assistant">AI Chat Assistant</Link>
                                </li>

                                {userRoles.includes('admin') && (
                                    <li className="nav-link">
                                        <Link to="/admin">Admin</Link>
                                    </li>)}
                                {userRoles.includes('pharmacien') && (
                                    <li className="nav-link">
                                        <Link to="/pos">Pharmacy POS</Link>
                                    </li>
                                )}
                                {userRoles.includes('supplier') && (
                                    <li className="nav-link">
                                        <Link to="/suppliers">Supplier</Link>
                                    </li>
                                )}

                                <li className="nav-link">
                                    <Link to="#">More <FaCaretDown /> </Link>
                                    <div className="dropdown">
                                        <ul>
                                            <li className="dropdown-link">
                                                <Link to="https://meditracker.blogspot.com/">Blog<FaBlogger /></Link>
                                            </li>
                                            <li className="dropdown-link">
                                                <Link to="https://forum.induwara.dev.srijaya.lk">Forum<MdForum /></Link>
                                            </li>
                                            <div className="arrow"></div>
                                        </ul>
                                    </div>
                                </li>

                                {isAuthenticated && (
                                    <li className="nav-link">
                                        <Link to="/orders">
                                            {cartCount > 0 &&
                                                <span className="cart-count">{cartCount}</span> // Displaying cart count
                                            }
                                            Orders
                                            <FaShoppingCart />
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>

                        {!isAuthenticated ? (
                            <div className="log-sign">
                                <button onClick={() => loginWithRedirect()} className="btn transparent">Login</button>
                                <button onClick={() => loginWithRedirect()} className="btn solid">Register</button>
                            </div>
                        ) : (
                            <div className="log-sign">
                                <div className="logged-user-details">
                                    <img src={user.picture} alt={user.name} />
                                    <div className="logged-text">
                                        <h2>{user.name}</h2>
                                        <button onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="hamburger-menu-container">
                        <div className="hamburger-menu">
                            <div></div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}