import React, { useState } from 'react';

const SearchBar = ({ onSearch, medicineList }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = () => {
        onSearch(searchTerm);
    };

    return (
        <div className="search-bar">
            <input
                type="text"
                list="medicines"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for a medicine..."
            />
            <datalist id="medicines">
                {medicineList.map((medicine, index) => (
                    <option key={index} value={medicine} />
                ))}
            </datalist>
            <button onClick={handleSearch}>Search</button>
        </div>
    );
};

export default SearchBar;
