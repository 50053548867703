import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaUserLock, FaShieldAlt, FaExclamationTriangle } from 'react-icons/fa';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const shake = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
`;

const UnauthorizedScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #ff9a9e 0%, #fecfef 100%);
  font-family: 'Arial', sans-serif;
  color: #ffffff;
  text-align: center;
  animation: ${fadeIn} 1.5s ease-in-out;
`;

const UnauthorizedContent = styled.div`
  max-width: 600px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WarningIcon = styled(FaExclamationTriangle)`
  margin-right: 10px;
  animation: ${shake} 0.5s ease-in-out infinite;
`;

const Description = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

const AuthIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const AuthIcon = styled.div`
  font-size: 3rem;
`;

const Unauthorized = () => {
    return (
        <UnauthorizedScreenContainer>
            <UnauthorizedContent>
                <Title>
                    <WarningIcon />
                    Unauthorized
                </Title>
                <Description>You do not have access to this page.</Description>
                <p>Your security matters. Please contact the administrator if you believe this is an error.</p>
                <AuthIcons>
                    <AuthIcon as={FaUserLock} />
                    <AuthIcon as={FaShieldAlt} />
                </AuthIcons>
            </UnauthorizedContent>
        </UnauthorizedScreenContainer>
    );
};

export default Unauthorized;
