import React from 'react';
import "../styles/pos.css";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';
import AdBanner from '../AdBanner';
import styled from 'styled-components';

const AdBanneContainer = styled.div`
padding: 0 0 60px 15px !important;
    `;

const PosLayout = ({ children, type }) => {
    return (
        <div className='posContainer'>
            <div className='posBody' style={{ display: 'flex', flex: 1 }}>
                <Sidebar type={type} />
                <div style={{ flex: 1 }}>
                    <Navbar type={type} />
                <AdBanner />
                    <main>{children}
                        <AdBanneContainer>
                        <AdBanner/>
                        </AdBanneContainer>
                    </main>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PosLayout;
