import React from 'react';
import styled, { keyframes } from 'styled-components';
import { AiOutlineRobot } from 'react-icons/ai';

// Unauthorized component to be displayed when the user is not authenticated
const UnauthorizedAIChat = () => {
    return (
        <Container>
            <Content>
                <IconWrapper>
                    <StyledIcon />
                </IconWrapper>
                <MainText>
                    Unauthorized Access! <br /> <Highlight>Please Login to MediTracker</Highlight>
                </MainText>
                <Description>
                    You need to be authenticated to access the MediTracker AI health assistant.
                </Description>
                <SubText>Powered by MediTracker</SubText>
            </Content>
        </Container>
    );
};

// Sample CSS for unauthorized users' UI with animations
const float = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  100% {
    transform: scale(1);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #7f7fd5, #86a8e7, #91eac9);
  color: #ffffff;
  text-align: center;
  padding: 20px;
  overflow: hidden;
`;

const Content = styled.div`
  max-width: 600px;
  width: 100%;
  background: linear-gradient(145deg, #0f2027, #203a43, #2c5364);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 20px 40px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4), 0 30px 60px rgba(0, 0, 0, 0.3);
  }
`;

const IconWrapper = styled.div`
  font-size: 100px;
  margin-bottom: 30px;
`;

const StyledIcon = styled(AiOutlineRobot)`
  font-size: 100px;
  color: #ffcc00;
  animation: ${float} 3s infinite ease-in-out, ${pulse} 2s infinite ease-in-out;
  filter: drop-shadow(0 0 20px #ffcc00);
`;

const MainText = styled.h1`
  font-size: 36px;
  margin-bottom: 15px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1.5px;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
`;

const Highlight = styled.span`
  background: linear-gradient(90deg, #fcb045 0%, #fd1d1d 50%, #833ab4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #e0e0e0;
`;

const SubText = styled.p`
  font-size: 16px;
  color: #b0b0b0;
  font-style: italic;
`;

export default UnauthorizedAIChat;
