// src/pages/Auth/Login.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaSignInAlt } from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #89f7fe 0%, #66a6ff 100%);
  font-family: 'Arial', sans-serif;
  color: #ffffff;
  text-align: center;
  animation: ${fadeIn} 1.5s ease-in-out;
`;

const LoginContent = styled.div`
  max-width: 400px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignInIcon = styled(FaSignInAlt)`
  margin-right: 10px;
  font-size: 3rem;
  animation: ${bounce} 2s infinite;
`;

const LoginButton = styled.button`
  font-size: 1.2rem;
  padding: 10px 20px;
  background-color: #ff5f6d;
  background-image: linear-gradient(315deg, #dd0032 0%, #592be4 74%);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #ff3b47;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  margin-bottom: 20px;
  color: #f0f0f0;
`;

const Login = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <LoginContainer>
            <LoginContent>
                <Title>
                    <SignInIcon />
                    Login
                </Title>
                <Description>
                    To continue, please log in.
                </Description>
                <LoginButton onClick={() => loginWithRedirect()}>Log In</LoginButton>
            </LoginContent>
        </LoginContainer>
    );
};

export default Login;
