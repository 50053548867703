import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AddOrderModal from '../Tracker/AddOrderModal';

const HospitalList = ({ hospitals, onFocus, selectedMedicine }) => {
    const { isAuthenticated } = useAuth0();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedHospital, setSelectedHospital] = useState(null);

    const handleOrderClick = (hospital) => {
        setSelectedHospital(hospital);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedHospital(null);
    };

    return (
        <div className="hospital-list">
            <ul>
                {hospitals.length > 0 ? (
                    hospitals.map((hospital, index) => (
                        <li key={index}>
                            <div>
                                <h3>{hospital.name}</h3>
                                <h4><a href={`tel:${hospital.phone_number}`}>{hospital.phone_number}</a></h4>
                                <p>{hospital.medicine}</p>
                                {hospital.price_exposure && isAuthenticated && (
                                    <p>Price: Rs. {hospital.price}</p>
                                )}
                            </div>
                            <div className="buttons">
                                <button className="focus-button" onClick={() => onFocus(hospital)}>View ➡️</button>
                                <button
                                    className="directions-button"
                                    onClick={() => {
                                        const url = `https://www.google.com/maps/dir/?api=1&destination=${hospital.lat},${hospital.lng}`;
                                        window.open(url, '_blank');
                                    }}
                                >
                                    Directions
                                </button>
                                <button onClick={() => handleOrderClick(hospital)}> 🛒 Order </button>
                            </div>
                        </li>
                    ))
                ) : (
                    <p>No data available</p>
                )}
            </ul>
            {/* Show Modal when open */}

            {isModalOpen && (
                <AddOrderModal
                    hospital={selectedHospital}
                    onClose={closeModal}
                    selectedMedicine={selectedMedicine}
                />
            )}
        </div>
    );
};

export default HospitalList;
