// src/Components/Suppliers/OrderTable.jsx
import React from 'react';

const OrderTable = ({ orders, onAccept }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>Pharmacy Name</th>
                    <th>Medicine Name</th>
                    <th>Order Date</th>
                    <th>Order Quantity</th>
                    {/* <th>Status</th> */}
                    <th>Accept</th>
                </tr>
            </thead>
            <tbody>
                {orders.map(order => (
                    <tr key={order.id}>
                        <td>{order.pharmacy_name}</td>
                        <td>{order.medicine_name}</td>
                        <td>{new Date(order.order_date).toLocaleDateString()}</td>
                        <td>{order.order_quantity}</td>
                        {/* <td>{order.status}</td> */}
                        <td>
                            {order.status === 'Pending' && (
                                <>
                                    <button onClick={() => onAccept(order.id)}>Accept</button>
                                </>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default OrderTable;
