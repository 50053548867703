import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaCapsules, FaLock, FaUserShield } from 'react-icons/fa';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const LoadingScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #89f7fe 0%, #66a6ff 100%);
  font-family: 'Arial', sans-serif;
  color: #ffffff;
  text-align: center;
`;

const LoadingContent = styled.div`
  animation: ${fadeIn} 1.5s ease-in-out;
`;

const MedicineIcon = styled(FaCapsules)`
  font-size: 4rem;
  margin-bottom: 20px;
  animation: ${pulse} 1.5s infinite;
`;

const AuthIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const AuthIcon = styled.div`
  font-size: 2rem;
  margin: 0 10px;
  animation: ${bounce} 2s infinite;
`;

const Spinner = styled.div`
  margin: 30px auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top-color: #ffffff;
  animation: ${spin} 1s linear infinite;
`;

const AuthLoadingScreen = () => {
    return (
        <LoadingScreenContainer>
            <LoadingContent>
                <MedicineIcon />
                <h1>Authenticating...</h1>
                <p>Securing your access to health...</p>
                <AuthIcons>
                    <AuthIcon as={FaLock} />
                    <AuthIcon as={FaUserShield} />
                </AuthIcons>
                <Spinner />
            </LoadingContent>
        </LoadingScreenContainer>
    );
};

export default AuthLoadingScreen;
