import React from 'react';
import './styles/footer.css';
import { Link } from 'react-router-dom'; // Assuming you're using React Router
import Logo from '../logo.png';
import { SiHackerrank } from "react-icons/si";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="footer-col flex-col">
                            <Link to="/"><img src={Logo} alt='Logo'/></Link>
                            <p>
                                    Our medicine availability tracker helps you stay informed about the availability of your prescribed medications.
                            </p>
                    </div>
                    <div className="footer-col">
                        <h4>Services</h4>
                        <ul>
                            <li><Link to="/medi-tracker">Medicine tracker</Link></li>
                            <li><Link to="/scheduling">Medicine Scheduling</Link></li>
                            <li><Link to="/assistant">AI Assistant</Link></li>
                            <li><Link to="#"></Link></li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h4>Get Help</h4>
                        <ul>
                            <li><Link to="https://meditracker.blogspot.com/">Blog</Link></li>
                            <li><Link to="https://forum.induwara.dev.srijaya.lk">Forum</Link></li>
                            <li><Link to="#"></Link></li>
                            <li><Link to="#"></Link></li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h4>Follow Us</h4>
                        <div className="social-links">
                            <Link to="#"><FaGithub /></Link>
                            <Link to="#"><FaLinkedin /></Link>
                            <Link to="#"><SiHackerrank /></Link>
                            <Link to="#"><BsDiscord /></Link>
                        </div>
                    </div>
                </div>

                <h5 className='footer-rights'>MediTrack™  |  2024  |  All rights Reserved! </h5>
            </div>
        </footer>
    );
};

export default Footer;
