import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../../utils/supabase';
import OrderHistoryTable from '../../Components/Suppliers/OrderHistoryTable';
import SupplierContext from '../../Components/Suppliers/SupplierContext';

const AcceptedOrders = () => {
    const [orders, setOrders] = useState([]);
    const supplierId = 1; //useContext(SupplierContext); // Get the current supplier ID from context

    useEffect(() => {
        const fetchOrderHistory = async () => {
            if (!supplierId) return;

            const { data, error } = await supabase
                .from('orders')
                .select('*, pharmacies(name), medicines(name), suppliers(name)')
                .eq('supplier_id', supplierId)
                .eq('status', "Accepted");

            if (error) {
                console.error('Error fetching order history:', error.message);
            } else {
                const formattedOrders = data.map(order => ({
                    id: order.id,
                    pharmacy_name: order.pharmacies.name,
                    medicine_name: order.medicines.name,
                    order_date: order.order_date,
                    order_quantity: order.order_quantity,
                    status: order.status,
                    supplier_name: order.suppliers ? order.suppliers.name : 'N/A',
                }));
                setOrders(formattedOrders);
            }
        };

        fetchOrderHistory();

        const orderSubscription = supabase
            .channel('orders_channel')
            .on('postgres_changes', { event: '*', schema: 'public', table: 'orders' }, payload => {
                fetchOrderHistory(); // Refetch orders when any change occurs
            })
            .subscribe();
        
        return () => {
            // Unsubscribe from the channel when the component is unmounted
            supabase.removeChannel(orderSubscription);
        };
        
    }, [supplierId]);

    const handleReject = async (orderId) => {
        const { error } = await supabase
            .from('orders')
            .update({ status: 'Pending', supplier_id: null })
            .eq('id', orderId);

        if (error) {
            console.error('Error rejecting order:', error.message);
        }
    };

    const handleComplete = async (orderId) => {
        const { error } = await supabase
            .from('orders')
            .update({ status: 'Completed', supplier_id: supplierId })
            .eq('id', orderId);

        if (error) {
            console.error('Error rejecting order:', error.message);
        }
    }
    return (
        <div className='pos_container'>
            <h1>Accepted Orders</h1>
            <OrderHistoryTable orders={orders} onReject={handleReject} onComplete={handleComplete} />
        </div>
    );
};

export default AcceptedOrders;
