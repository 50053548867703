import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import SearchBar from '../Components/SearchBar';
import HospitalList from '../Components/HospitalList';
import Map from '../Components/Map';
import './Tracker.css';
import { supabase } from '../utils/supabase';
import { useAuth0 } from '@auth0/auth0-react';
import AdBanner from '../Components/AdBanner';

// Styled components for alert message
const PriceAlert = styled.div`
    background-color: #ffcc00;
    color: #333;
    padding: 15px;
    margin: 20px 0;
    border-radius: 5px;
    text-align: center;
    font-size: 1.1em;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AlertText = styled.span`
    margin-left: 10px;
    font-weight: bold;
    color: #d9534f;
    font-family: 'Arial', sans-serif;
`;

export default function Tracker() {
    const [hospitals, setHospitals] = useState([]);
    const [selectedMedicine, setSelectedMedicine] = useState('');
    const [userLocation, setUserLocation] = useState(null);
    const [focusedHospital, setFocusedHospital] = useState(null);
    const [medicineList, setMedicineList] = useState([]);
    const { isAuthenticated } = useAuth0();

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setUserLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            });
        }

        // Fetch available medicines for the datalist
        const fetchMedicines = async () => {
            const { data, error } = await supabase
                .from('medicines')
                .select('name');

            if (error) {
                console.error('Error fetching medicines:', error.message);
            } else {
                setMedicineList(data.map(medicine => medicine.name));
            }
        };

        fetchMedicines();

        // Subscribe to changes in stock and medicines tables
        const subscribeToTables = () => {
            const stockChannel = supabase
                .channel('stock-channel')
                .on('postgres_changes', { event: '*', schema: 'public', table: 'stock' }, payload => {
                    if (selectedMedicine) {
                        handleSearch(selectedMedicine);
                    }
                })
                .subscribe();

            const medicinesChannel = supabase
                .channel('medicines-channel')
                .on('postgres_changes', { event: '*', schema: 'public', table: 'medicines' }, payload => {
                    if (selectedMedicine) {
                        handleSearch(selectedMedicine);
                    }
                })
                .subscribe();

            return () => {
                supabase.removeChannel(stockChannel);
                supabase.removeChannel(medicinesChannel);
            };
        };

        subscribeToTables();
    }, [selectedMedicine]);

    // Function to calculate distance between two points (Haversine formula)
    function calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // Radius of the Earth in km
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in km
        return distance;
    }

    const handleSearch = useCallback(async (medicine) => {
        try {
            const { data, error } = await supabase
                .from('stock')
                .select(`
                pharmacies(name, phone_number, lat, lng, price_exposure),
                medicines(name),
                price,
                quantity
            `)
                .ilike('medicines.name', `%${medicine}%`) // Ensures case-insensitive search for the medicine
                .gt('quantity', 0); // Filters out medicines with a quantity of 0

            if (error) {
                console.error('Error fetching data from Supabase:', error.message);
                return;
            }

            const filteredHospitals = data.map(stockItem => {
                const pharmacy = stockItem.pharmacies;
                const medicineData = stockItem.medicines;

                // Ensure pharmacy and medicine data exists
                if (!pharmacy || !medicineData) {
                    console.warn('Missing pharmacy or medicine data:', stockItem);
                    return null; // Skip this item
                }

                return {
                    name: pharmacy.name,
                    phone_number: pharmacy.phone_number,
                    medicine: medicineData.name,
                    lat: pharmacy.lat,
                    lng: pharmacy.lng,
                    price_exposure: pharmacy.price_exposure,
                    price: stockItem.price,
                    quantity: stockItem.quantity, // Include quantity in the object
                };
            }).filter(item => item !== null); // Filter out any null values

            if (userLocation) {
                filteredHospitals.sort((a, b) => {
                    const distanceA = calculateDistance(userLocation.lat, userLocation.lng, a.lat, a.lng);
                    const distanceB = calculateDistance(userLocation.lat, userLocation.lng, b.lat, b.lng);
                    return distanceA - distanceB;
                });
            }

            setHospitals(filteredHospitals);
        } catch (error) {
            console.error('Error in handleSearch:', error);
        }
    }, [userLocation]);

    const handleFocus = (hospital) => {
        setFocusedHospital(hospital);
    };

    // Ensure handleSearch is called when selectedMedicine or medicineList changes
    useEffect(() => {
        if (selectedMedicine) {
            handleSearch(selectedMedicine);
        }
    }, [selectedMedicine, handleSearch]);

    return (
        <div className="tracker">
            <SearchBar onSearch={setSelectedMedicine} medicineList={medicineList} />
            <AdBanner />

            {selectedMedicine && hospitals.length === 0 && <PriceAlert>{selectedMedicine} is Not Available in Stock </PriceAlert>}
            <div className="tracker-content">
                <div>
                    {!isAuthenticated && hospitals.length > 0 && (
                        <PriceAlert>
                            {hospitals.some(h => h.price_exposure) ? (
                                <AlertText>🔒 Please Log in to order medicines Online 🛒 <br/> and 💊 check medicine prices.</AlertText>
                            ) : (
                                <AlertText>🔒 Please Log in to order medicines Online 🛒.</AlertText>
                            )}
                        </PriceAlert>
                    )}
                    {hospitals.length > 0 && (
                        <>

                            <HospitalList
                                hospitals={hospitals}
                                selectedMedicine={selectedMedicine}
                                onFocus={handleFocus}
                            />
                        </>
                    )}
                </div>
                <Map hospitals={hospitals} userLocation={userLocation} focusedHospital={focusedHospital} />
            </div>
            <AdBanner />
        </div>
    );
}
