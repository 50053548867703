import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import PrivateRoute from './utils/PrivateRoute';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLoading from './Components/AuthLoading';

import Layout from './Components/Layout';
import Home from './Home/Home';
import Contact from './Pages/Contact';
import Tracker from './Tracker/Tracker';
import Scheduler from './Scheduler/Scheduler';
import Login from './Pages/Auth/Login';
import Unauthorized from './Pages/Auth/Unauthorized';
import AIComingSoon from './Assistant/aiCommingSoon';
import ChatBot from './Assistant/assistant';
import UserOrders from './Pages/UserOrders';

// POS Routes Imports
import PosLayout from './Components/Pos/PosLayout';
import Purchase from './Pages/Pos/Purchase';
import Pos from './Pages/Pos/Pos';
import Stock from './Pages/Pos/Stock';
import Suppliers from './Pages/Pos/Suppliers';
import CustomerOrders from './Pages/Pos/CustomerOrders'
import AllMedicines from './Pages/Pos/AllMedicines';

// Supplier Routes Imports
import Orders from './Pages/Suppliers/Orders';
import OrderHistory from './Pages/Suppliers/OrderHistory';
import AcceptedOrders from './Pages/Suppliers/AcceptedOrders';

function App() {
    const { isLoading } = useAuth0();

    if (isLoading ) {
        return <AuthLoading />;
    }

    return (
        <div className="App">
            <div>
                <BrowserRouter>
                    <Routes>
                        <Route path='/login' element={<Login />} />
                        <Route path='/unauthorized' element={<Unauthorized />} />
                        <Route path='/' element={<Layout><Home /></Layout>} />
                        <Route path='/contact' element={<Layout><Contact /></Layout>} />
                        <Route path='/medi-tracker' element={<Layout><Tracker /></Layout>} />
                        <Route path='/scheduling' element={<Layout><Scheduler /></Layout>} />
                        <Route path='/assistant' element={<Layout><ChatBot /></Layout>} />
                        <Route path='/orders' element={<Layout><UserOrders /></Layout>} />

                        {/* POS Routes */}
                        <Route path="/pos/purchase" element={<PrivateRoute requiredRole="pharmacien"><PosLayout><Purchase /></PosLayout></PrivateRoute>} />
                        <Route path="/pos/" element={<PrivateRoute requiredRole="pharmacien"><PosLayout><Pos /></PosLayout></PrivateRoute>} />
                        <Route path="/pos/stock" element={<PrivateRoute requiredRole="pharmacien"><PosLayout><Stock /></PosLayout></PrivateRoute>} />
                        <Route path="/pos/suppliers" element={<PrivateRoute requiredRole="pharmacien"><PosLayout><Suppliers /></PosLayout></PrivateRoute>} />
                        <Route path="/pos/orders" element={<PrivateRoute requiredRole="pharmacien"><PosLayout><CustomerOrders /></PosLayout></PrivateRoute>} />
                        <Route path="/pos/medicines" element={<PrivateRoute requiredRole="pharmacien"><PosLayout><AllMedicines /></PosLayout></PrivateRoute>} />
                                               

                        {/* Supplier Routes */}
                        <Route path="/suppliers" element={<PrivateRoute requiredRole="supplier"><PosLayout type="supplier"><Orders /></PosLayout></PrivateRoute>} />
                        <Route path="/suppliers/accepted-orders" element={<PrivateRoute requiredRole="supplier"><PosLayout type="supplier"><AcceptedOrders /></PosLayout></PrivateRoute>} />
                        <Route path="/suppliers/order-history" element={<PrivateRoute requiredRole="supplier"><PosLayout type="supplier"><OrderHistory /></PosLayout></PrivateRoute>} />
                    </Routes>
                </BrowserRouter>
            </div>
        </div>
    );
}

export default App;