import React, { useEffect, useState, useContext } from 'react';
import { supabase } from '../../utils/supabase';
import PharmacyContext from '../../Components/Pos/PharmacyContext';

const Purchase = () => {
    const [orders, setOrders] = useState([]);
    const [lowStockLimit, setLowStockLimit] = useState(10);
    const [selectedMedicine, setSelectedMedicine] = useState(null);
    const [pharmacyData, setPharmacyData] = useState({ medicines: [], orders: [] });
    const pharmacy_id = 1; //useContext(PharmacyContext);
    const [modalType, setModalType] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(''); // State for status filter

    useEffect(() => {
        const fetchMedicines = async () => {
            const { data, error } = await supabase
                .from('stock')
                .select('*, medicines(name)')
                .eq('pharmacy_id', pharmacy_id)
                .lt('quantity', lowStockLimit);

            if (error) {
                console.error('Error fetching medicines:', error.message);
            } else {
                // Format the data to include the necessary medicine details
                const formattedMedicines = data.map(stockItem => ({
                    id: stockItem.medicine_id,
                    name: stockItem.medicines.name,
                    quantity: stockItem.quantity,
                }));
                setPharmacyData(prev => ({ ...prev, medicines: formattedMedicines }));
            }
        };

        const fetchOrders = async () => {
            const { data: ordersData, error: ordersError } = await supabase
                .from('orders')
                .select('*, medicines(name), suppliers(name)')
                .eq('pharmacy_id', pharmacy_id);

            if (ordersError) {
                console.error('Error fetching orders:', ordersError.message);
            } else {
                // Join orders with medicine names and supplier names (handle null suppliers)
                const ordersWithDetails = ordersData.map(order => ({
                    ...order,
                    medicine_name: order.medicines.name,
                    supplier_name: order.suppliers ? order.suppliers.name : '', // Handle null supplier
                }));
                setOrders(ordersWithDetails);
            }
        };

        if (pharmacy_id) {
            fetchMedicines();
            fetchOrders();

            // Set up subscriptions to listen for changes in the 'stock' and 'orders' tables
            const stockChannel = supabase
                .channel('stock-changes')
                .on(
                    'postgres_changes',
                    { event: '*', schema: 'public', table: 'stock' },
                    fetchMedicines // Re-fetch medicines whenever any change occurs in 'stock'
                )
                .subscribe();

            const ordersChannel = supabase
                .channel('orders-changes')
                .on(
                    'postgres_changes',
                    { event: '*', schema: 'public', table: 'orders' },
                    fetchOrders // Re-fetch orders whenever any change occurs in 'orders'
                )
                .subscribe();

            // Cleanup subscriptions when the component unmounts
            return () => {
                supabase.removeChannel(stockChannel);
                supabase.removeChannel(ordersChannel);
            };
        }
    }, [pharmacy_id, lowStockLimit]);

    const openModal = (type, medicine) => {
        setSelectedMedicine(medicine);
        setModalType(type);
    };

    const closeModal = () => {
        setSelectedMedicine(null);
        setModalType('');
    };

    const handleAddOrder = async () => {
        const orderQty = parseInt(document.getElementById('orderQty').value, 10);
        if (orderQty && selectedMedicine) {
            const { data, error } = await supabase
                .from('orders')
                .insert([{
                    medicine_id: selectedMedicine.id,
                    pharmacy_id,
                    order_quantity: orderQty,
                    status: 'Pending'
                }])
                .select(); // Retrieve the inserted record

            if (!error && data?.length > 0) {
                // Add the new order to the Table
                setOrders(prevOrders => [
                    ...prevOrders,
                    {
                        ...data[0], // Spread the inserted order data directly
                        medicine_name: selectedMedicine.name,
                    }
                ]);
                closeModal();
            } else {
                console.error('Error adding order:', error?.message || 'Unexpected response');
            }
        }
    };

    const handleRemoveOrder = async (orderId) => {
        const { error } = await supabase
            .from('orders')
            .delete()
            .eq('id', orderId);

        if (!error) {
            setOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
        } else {
            console.error('Error removing order:', error.message);
        }
    };

    return (
        <div className="pos_container">
            <h1>Purchase Order Page</h1>

            {/* Low Stock Table */}
            <div>
                <h2>Low Stock Medicines</h2>
                <label>
                    Low Stock Limit:
                    <input
                        type="number"
                        style={{ width: 'auto', marginLeft: '10px' }}
                        value={lowStockLimit}
                        onChange={(e) => setLowStockLimit(parseInt(e.target.value, 10))}
                    />
                </label>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pharmacyData.medicines.map(medicine => (
                            <tr key={medicine.id}>
                                <td>{medicine.name}</td>
                                <td>{medicine.quantity}</td>
                                <td>
                                    <button onClick={() => openModal('order', medicine)}>Add Order</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {modalType && (
                <div className="modal">
                    <div className="modal-content">
                        {modalType === 'order' && (
                            <>
                                <h2>Add Order for {selectedMedicine?.name}</h2>
                                <input id="orderQty" type="number" placeholder="Enter quantity to order" />
                                <button onClick={handleAddOrder}>Add Order</button>
                                <button onClick={closeModal}>Cancel</button>
                            </>
                        )}
                    </div>
                </div>
            )}

            {/* Orders Table */}
            <div>
                <h2>Orders</h2>
                <label>
                    Filter by Status:
                    <select
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        <option value="">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Accepted">Accepted</option>
                        <option value="Completed">Completed</option>
                    </select>
                </label>
                <table>
                    <thead>
                        <tr>
                            <th>Medicine Name</th>
                            <th>Order Date</th>
                            <th>Order Quantity</th>
                            <th>Supplier</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders
                            .filter(order => selectedStatus === '' || order.status === selectedStatus) // Filter by status
                            .map(order => (
                                <tr key={order.id}>
                                    <td>{order.medicine_name}</td>
                                    <td>{new Date(order.order_date).toLocaleDateString()}</td>
                                    <td>{order.order_quantity}</td>
                                    <td>{order.supplier_name}</td>
                                    <td>{order.status}</td>
                                    <td>
                                        <button onClick={() => handleRemoveOrder(order.id)}>Remove</button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Purchase;
