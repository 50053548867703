import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const Map = ({ hospitals, focusedHospital, userLocation }) => {
    const [map, setMap] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    useEffect(() => {
        if (focusedHospital && map && userLocation) {
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: { lat: userLocation.lat, lng: userLocation.lng },
                    destination: { lat: focusedHospital.lat, lng: focusedHospital.lng },
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result);
                        map.panTo({ lat: focusedHospital.lat, lng: focusedHospital.lng });
                        map.setZoom(15);
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }
            );
        }
    }, [focusedHospital, map, userLocation]);

    if (!isLoaded) {
        return <div>Loading...</div>;  // Display a loading indicator
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={userLocation || { lat: 6.9271, lng: 79.8612 }}
            zoom={10}
            onLoad={map => setMap(map)}
            options={{
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                disableDefaultUI: false,
                zoomControlOptions: window.google && window.google.maps ? {
                    position: window.google.maps.ControlPosition.RIGHT_BOTTOM
                } : {},
                streetViewControlOptions: window.google && window.google.maps ? {
                    position: window.google.maps.ControlPosition.RIGHT_BOTTOM
                } : {},
                fullscreenControlOptions: window.google && window.google.maps ? {
                    position: window.google.maps.ControlPosition.RIGHT_BOTTOM
                } : {}
            }}
        >
            {userLocation && (
                <Marker position={userLocation} icon={{ url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' }} />
            )}
            {hospitals.map((hospital, index) => (
                <Marker key={index} position={{ lat: hospital.lat, lng: hospital.lng }} />
            ))}
            {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
            )}
        </GoogleMap>
    );
};

export default Map;