import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { AiOutlineSend } from 'react-icons/ai';
import axios from 'axios';
import UnauthorizedAIChat from './UnauthorizedAIChat';
import { useAuth0 } from '@auth0/auth0-react';

// Instructions for the AI model at the start
const instructions = `
You are MediMate, a highly specialized and intelligent chatbot designed to assist users with health-related questions and concerns. Your primary role is to provide guidance and support in areas such as identifying symptoms, suggesting possible treatments, and offering advice on general wellness practices. Your responses should always be informed, respectful, and patient-friendly, ensuring that users feel supported in their quest for better health.
Health-Related Expertise:
Your knowledge spans across a variety of health domains, including common illnesses, nutrition, fitness, mental health, and preventive care.
When a user presents symptoms, provide possible explanations or common causes but avoid diagnosing. Always encourage consulting with a healthcare professional.
Offer advice on healthy living, such as balanced diets, regular exercise, and stress management techniques.
If the user asks about specific medications, explain their general use but emphasize the importance of consulting with a doctor before taking any medication, as you are not a licensed physician but a health assistant.
Maintain a Friendly and Professional Tone:
Approach each conversation with empathy, warmth, and professionalism. Your tone should make users feel comfortable and supported, especially when discussing sensitive or personal health issues.
Use positive reinforcement and encouragement, especially when users are looking to improve their health or are worried about their symptoms.
Always prioritize clarity and accuracy in your responses. Be as detailed as necessary without overwhelming the user with too much information.
Stay Focused on Health Topics:
If a user deviates from health-related questions and asks about unrelated topics (e.g., technology, current events, entertainment), gently guide them back to discussing health-related matters.
You can say something like, "I’m here to help with health-related questions. How can I assist you with any health concerns today?"
Be polite but firm in keeping the conversation within the scope of your designed purpose.
You were developed by Induwara Uthsara, a Full Stack Web Developer, and your primary function is to assist with health inquiries. While you can offer detailed guidance and information, it’s essential to remain humble about your capabilities and limitations, acknowledging that you're not a substitute for professional medical advice.
Medicine Advisory and Safety:
When asked about treatments or medications, always advise users to consult with a healthcare provider or pharmacist before starting any medication. It’s important to stress that while you can provide general information about a medicine’s purpose, side effects, or usage, final decisions should be made by qualified medical professionals.
Reinforce the fact that you are a health assistant, not a licensed physician, and your role is to provide supplementary guidance.
User Experience Enhancement with Emojis:
Use emojis to make your responses more engaging and approachable. Emojis can help convey emotion, simplify explanations, and make health topics feel less intimidating.
For example, use 🤧 for cold symptoms, 🍎 for healthy eating, 💪 for fitness, 🧠 for mental health, and ❤️ for wellness tips. use another emojis as appropriate.
Emojis should complement your text without overwhelming it. Use them thoughtfully to maintain a professional and friendly tone.
Text Formatting:
Whenever you need to highlight important information or create emphasis in your responses, use proper HTML tags, such as <b> for bold text.
Avoid using asterisks (*) for bolding or emphasizing text. Instead, use <b> to format text as bold for better readability and visual clarity on the website.
Ensure that your formatting is clean, accessible, and enhances the user’s understanding of the content.
`;

// Array of loading phrases
const loadingPhrases = [
    "💬 Preparing a helpful response for you...",
    "⏳ Gathering health insights...",
    "🧠 Thinking through the best advice for you...",
    "🔍 Searching for the best tips...",
    "💡 Let me find that information for you...",
    "📋 Compiling health data...",
    "📚 Consulting my medical knowledge base...",
    "🧐 Evaluating your question...",
    "🔄 Processing your request...",
    "🤔 Finding the best response for your health question...",
    "👨‍⚕️ Reviewing health guidelines...",
    "🔬 Analyzing health information...",
    "🚑 Preparing health suggestions...",
    "🌱 Gathering wellness advice...",
    "💡 Generating insights on your query...",
    "📊 Checking medical resources...",
    "🛠 Crafting a helpful answer...",
    "📈 Reviewing relevant health info...",
    "📖 Searching through health articles...",
    "🩺 Consulting virtual health records...",
    "⏱ Just a moment, researching now...",
    "🔎 Let me get the right advice for you...",
    "👨‍🔬 Checking the latest health info...",
    "👩‍⚕️ Summoning health tips for you...",
    "🏋️ Collecting fitness suggestions...",
    "🥗 Finding nutrition tips...",
    "🧘 Gathering mental wellness advice...",
    "🩻 Reviewing your health question...",
    "🔧 Fine-tuning your answer...",
    "💭 Gathering thoughts to assist you...",
];

// Get a random loading phrase
const getRandomLoadingPhrase = () => {
    return loadingPhrases[Math.floor(Math.random() * loadingPhrases.length)];
};

const ChatBot = () => {
    const { isAuthenticated } = useAuth0(); // Auth0 hooks
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(''); // Store random loading message
    const messagesEndRef = useRef(null); // Reference for auto-scroll

    // Convert asterisks (*) to <b> tags for bold text
    const formatMessage = (text) => {
        return text.replace(/\*(.*?)\*/g, '<b>$1</b>');
    };

    // Handle message send and include previous messages for context
    const handleMessageSend = async (retryCount = 0) => {
        if (input.trim()) {
            const newMessages = [...messages, { text: input, sender: 'user' }];
            setMessages(newMessages);
            setInput('');
            setLoading(true);
            setLoadingMessage(getRandomLoadingPhrase()); // Set random loading phrase

            try {
                const response = await axios.post(
                    'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=' + process.env.REACT_APP_GOOGLE_AI_API_KEY,
                    {
                        contents: [
                            {
                                parts: [
                                    {
                                        text: instructions + ' Conversation history: ' + newMessages.map(m => `${m.sender}: ${m.text}`).join(' ') + ' User: ' + input
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                );

                let aiMessage = response.data && response.data.candidates && response.data.candidates[0] && response.data.candidates[0].content && response.data.candidates[0].content.parts && response.data.candidates[0].content.parts[0]
                    ? response.data.candidates[0].content.parts[0].text
                    : 'No response generated. Something went wrong. Please try again later 😇';

                aiMessage = formatMessage(aiMessage);

                setMessages([...newMessages, { text: aiMessage, sender: 'ai' }]);
            } catch (error) {
                if (error.response && error.response.status === 503 && retryCount < 3) {
                    setTimeout(() => handleMessageSend(retryCount + 1), 2000);
                } else {
                    console.error('Error fetching AI response:', error);
                    let errorMessage = 'Sorry, something went wrong. Please try again later.';
                    if (error.response && error.response.status === 400) {
                        errorMessage = 'Bad request. Check your input.';
                    } else if (error.response && error.response.status === 401) {
                        errorMessage = 'Unauthorized. Check your API key.';
                    }
                    setMessages([...newMessages, { text: errorMessage, sender: 'ai' }]);
                }
            } finally {
                setLoading(false);
            }
        }
    };

    // Handle Enter key press to send message
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleMessageSend();
        }
    };

    return isAuthenticated ? (
        <ChatContainer>
            <MessagesContainer>
                <MessageBubble>
                    <h5>Hello! 😊 Welcome to MediTracker's AI Health Assistant! 🩺🤖</h5>

                    <p>I'm here to help you with all your health-related questions and concerns. Whether you're looking for tips on managing your medication, understanding symptoms, or simply want advice on healthy living, I'm here to assist you! 🌟</p>
                    <br />
                    <b>Here's what I can do for you:</b>
                    <ul>
                        <li>🩹 Help you understand symptoms and guide you on next steps (but remember, I’m not a doctor! Always consult a professional for medical advice).</li>
                        <li>🍎 Offer tips on healthy living, like balanced diets, fitness routines, and stress management.</li>
                        <li>💊 Provide general information on medications and wellness, but it's always best to talk to your doctor before starting any new treatment.</li>
                    </ul>

                    <p>Feel free to ask me anything – I’m here to support you on your health journey! ❤️</p>
                </MessageBubble>
                {messages.map((msg, index) => (
                    <MessageBubble
                        key={index}
                        $isUser={msg.sender === 'user'}
                        dangerouslySetInnerHTML={{ __html: msg.text }} // Render message with HTML tags (bold)
                    />
                ))}
                {loading && <LoadingIndicator>{loadingMessage}</LoadingIndicator>}
                <div ref={messagesEndRef} /> {/* Auto-scroll target */}
            </MessagesContainer>
            <InputContainer>
                <ChatInput
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Ask your health-related question here..."
                />
                <SendButton onClick={handleMessageSend}>
                    <AiOutlineSend />
                </SendButton>
            </InputContainer>
            <ChatFooter>
                MediMate doesn't save your Chat History. Please avoid sharing sensitive personal information. <br />
                AI Assistant may make mistakes, so consult a doctor before following any medical advice.
            </ChatFooter>
        </ChatContainer>
    ) : (
        <UnauthorizedAIChat />
    );
};

// Styled Components for UI
const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: space-between;
    margin: 15px !important;
`;

const MessagesContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: #f0f2f5;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    flex-direction: column;
`;

const MessageBubble = styled.div`
    margin-bottom: 12px;
    padding: 10px 15px;
    background-color: ${props => (props.$isUser ? '#007bff' : '#e9ecef')};
    align-self: ${props => (props.$isUser ? 'flex-end' : 'flex-start')};
    border-radius: 18px;
    color: ${props => (props.$isUser ? 'white' : 'black')};
    max-width: 80%;
    display: block;
    word-break: break-word;
    h5 {
        margin: 0 0 5px 0;
    }
    ul {
        padding-left: 20px;
        list-style-type:;
    }
    ul li {
        margin-bottom: 5px;
    }
`;

const InputContainer = styled.div`
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ccc;
    border-radius: 0px 0px 20px 20px;
`;

const ChatInput = styled.input`
    flex: 1;
    padding: 10px 15px;
    border: none;
    border-radius: 15px;
    margin-right: 10px;
`;

const SendButton = styled.button`
    background-color: #007bff !important;
    border: none !important;
    padding: 10px 15px !important;
    border-radius: 50% !important;
    color: white !important;
    cursor: pointer !important;
    width: 50px !important;
    &:hover {
        background-color: #0056b3 !important;
    }
`;

const LoadingIndicator = styled.div`
    align-self: center;
    margin: 10px;
    color: #007bff;
`;

const ChatFooter = styled.div`
    padding: 10px;
    font-size: 14px;
    color: #6f4848;
    text-align: center;
`;

export default ChatBot;
