import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { useAuth0 } from "@auth0/auth0-react";
import styled from 'styled-components';

const OrderPage = styled.div`
    padding: 20px;
    font-family: Arial, sans-serif;
`;

const Title = styled.h1`
    text-align: center;
    margin-bottom: 20px;
`;

const FilterContainer = styled.div`
    margin-bottom: 20px;
    text-align: center;
`;

const Label = styled.label`
    font-size: 16px;
    margin-right: 10px;
`;

const Select = styled.select`
    padding: 5px;
    font-size: 16px;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
`;

const TableHead = styled.th`
    background-color: #f4f4f4;
    font-weight: bold;
    border: 1px solid #ddd;
    padding: 12px;
`;

const TableCell = styled.td`
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }

    &:hover {
        background-color: #f1f1f1;
    }
`;

const Button = styled.button`
    padding: 8px 12px;
    border: none;
    background-color: ${(props) => (props.cancel ? '#f44336' : '#4CAF50')};
    color: white;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 5px;
    margin: 5px auto;

    &:hover {
        background-color: ${(props) => (props.cancel ? '#d73833' : '#45a049')};
    }
`;

const Modal = styled.div`
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    text-align: center;
`;

const Input = styled.input`
    width: calc(100% - 20px);
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

export default function UserOrders() {
    const { user, isAuthenticated, getIdTokenClaims } = useAuth0();
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [filter, setFilter] = useState('all');  // Filter for order status
    const [userID, setUserID] = useState(null);

    // States for modal
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentOrder, setCurrentOrder] = useState(null); // Holds the order being edited or canceled

    // Form for editing order
    const [editOrderData, setEditOrderData] = useState({
        medicine: '',
        quantity: '',
        delivery_type: '',
        description: ''
    });

    // Fetch the current user's orders on mount
    useEffect(() => {
        const fetchUserOrders = async () => {
            if (isAuthenticated) {
                const claims = await getIdTokenClaims();
                setUserID(claims.sub);

                const { data, error } = await supabase
                    .from('customer_orders')
                    .select('*')
                    .eq('customer_id', claims.sub);

                if (!error) {
                    setOrders(data);
                    setFilteredOrders(data);
                } else {
                    console.error('Error fetching orders:', error);
                }
            }
        };

        fetchUserOrders();
    }, [isAuthenticated, getIdTokenClaims]);

    // Set up real-time updates with Supabase
    useEffect(() => {
        if (userID) {
            const subscription = supabase
                .channel('realtime-orders')
                .on(
                    'postgres_changes',
                    { event: '*', schema: 'public', table: 'customer_orders', filter: `customer_id=eq.${userID}` },
                    (payload) => {
                        console.log('Change received:', payload);
                        if (payload.eventType === 'INSERT' || payload.eventType === 'UPDATE' || payload.eventType === 'DELETE') {
                            // Fetch the updated orders again
                            fetchUpdatedOrders();
                        }
                    }
                )
                .subscribe();

            return () => {
                supabase.removeChannel(subscription);
            };
        }
    }, [userID]);

    // Fetch updated orders
    const fetchUpdatedOrders = async () => {
        const { data, error } = await supabase
            .from('customer_orders')
            .select('*')
            .eq('customer_id', userID);

        if (!error) {
            setOrders(data);
            filterOrders(filter, data); // Apply the current filter on update
        } else {
            console.error('Error fetching updated orders:', error);
        }
    };

    // Filter orders based on the status
    const filterOrders = (status, ordersData = orders) => {
        setFilter(status);
        if (status === 'all') {
            setFilteredOrders(ordersData);
        } else {
            setFilteredOrders(ordersData.filter(order => order.order_status === status));
        }
    };

    // Handle cancel order
    const handleCancelOrder = async (orderId) => {
        const { error } = await supabase
            .from('customer_orders')
            .update({ order_status: 'canceled' })
            .eq('id', orderId);

        if (!error) {
            setShowCancelModal(false);
            fetchUpdatedOrders();
        } else {
            console.error('Error canceling order:', error);
        }
    };

    // Handle edit order submission
    const handleEditOrder = async (orderId) => {
        const { error } = await supabase
            .from('customer_orders')
            .update(editOrderData)
            .eq('id', orderId);

        if (!error) {
            setShowEditModal(false);
            fetchUpdatedOrders();
        } else {
            console.error('Error editing order:', error);
        }
    };

    return (
        <OrderPage>
            <Title>Your Medicine Orders</Title>

            {/* Filter orders by status */}
            <FilterContainer>
                <Label>Filter by Status: </Label>
                <Select value={filter} onChange={(e) => filterOrders(e.target.value)}>
                    <option value="all">All</option>
                    <option value="pending">Pending</option>
                    <option value="completed">Completed</option>
                    <option value="canceled">Canceled</option>
                </Select>
            </FilterContainer>

            {/* Order list */}
            <Table>
                <thead>
                    <tr>
                        <TableHead>Medicine</TableHead>
                        <TableHead>Quantity</TableHead>
                        <TableHead>Delivery Type</TableHead>
                        <TableHead>Description</TableHead>
                        <TableHead>Pharmacy Name</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Actions</TableHead>
                    </tr>
                </thead>
                <tbody>
                    {filteredOrders.map(order => (
                        <TableRow key={order.id}>
                            <TableCell>{order.medicine}</TableCell>
                            <TableCell>{order.quantity}</TableCell>
                            <TableCell>{order.delivery_type}</TableCell>
                            <TableCell>{order.description}</TableCell>
                            <TableCell>{order.pharmacy_name}</TableCell>
                            <TableCell>{order.order_status}</TableCell>
                            <TableCell>
                                {/* <Button onClick={() => { setCurrentOrder(order); setShowEditModal(true); }}>Edit</Button> */}
                                <Button cancel onClick={() => { setCurrentOrder(order); setShowCancelModal(true); }}>Cancel</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>

            {/* Cancel Modal */}
            {showCancelModal && (
                <Modal>
                    <ModalContent>
                        <h2>Confirm Cancel Order</h2>
                        <p>Are you sure you want to cancel this order?</p>
                        <Button onClick={() => handleCancelOrder(currentOrder.id)}>Yes, Cancel</Button>
                        <Button cancel onClick={() => setShowCancelModal(false)}>No, Go Back</Button>
                    </ModalContent>
                </Modal>
            )}

            {/* Edit Modal */}
            {showEditModal && (
                <Modal>
                    <ModalContent>
                        <h2>Edit Order</h2>
                        <form onSubmit={(e) => { e.preventDefault(); handleEditOrder(currentOrder.id); }}>
                            <label>
                                Medicine:
                                <Input
                                    type="text"
                                    value={editOrderData.medicine}
                                    onChange={(e) => setEditOrderData({ ...editOrderData, medicine: e.target.value })}
                                />
                            </label>
                            <label>
                                Quantity:
                                <Input
                                    type="number"
                                    value={editOrderData.quantity}
                                    onChange={(e) => setEditOrderData({ ...editOrderData, quantity: e.target.value })}
                                />
                            </label>
                            <label>
                                Delivery Type:
                                <Input
                                    type="text"
                                    value={editOrderData.delivery_type}
                                    onChange={(e) => setEditOrderData({ ...editOrderData, delivery_type: e.target.value })}
                                />
                            </label>
                            <label>
                                Description:
                                <Input
                                    type="text"
                                    value={editOrderData.description}
                                    onChange={(e) => setEditOrderData({ ...editOrderData, description: e.target.value })}
                                />
                            </label>
                            <Button type="submit">Save Changes</Button>
                        </form>
                        <Button cancel onClick={() => setShowEditModal(false)}>Cancel</Button>
                    </ModalContent>
                </Modal>
            )}
        </OrderPage>
    );
}
