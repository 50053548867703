import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLoading from '../Components/AuthLoading';

const PrivateRoute = ({ children, requiredRole }) => {
    const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
    const [userRoles, setUserRoles] = useState([]);
    const [loadingRoles, setLoadingRoles] = useState(true);
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchUserRoles = async () => {
            //console.log("isAuthenticated ::",isAuthenticated);
            if (isAuthenticated) {
                //console.log("Inside isAuthenticated ::",isAuthenticated);
                const claims = await getIdTokenClaims();
                // //console.log("Claims ::",claims);
                const rolesFromToken = claims[baseURL+'/roles'] || [];  // Replace with your namespace
                //console.log('Roles from token:', rolesFromToken);  // Debugging line
                setUserRoles(rolesFromToken);
                setLoadingRoles(false); // Ensure loading state is updated
            }
        };

        fetchUserRoles();
    }, [isAuthenticated, getIdTokenClaims]);

    // Redirect to login if the user is not authenticated
    if (!isAuthenticated && !isLoading) {
        return <Navigate to="/login" />;
    }

    if (isLoading || loadingRoles) {
        return <AuthLoading />;
    }

    // Check if user has the required role
    const hasRequiredRole = userRoles.includes(requiredRole);
    //console.log("Required Role ::",requiredRole);
    //console.log("Has Required Role ::",hasRequiredRole);

    if (!hasRequiredRole) {
        return <Navigate to="/unauthorized" />;
    }

    return children;
};

export default PrivateRoute;
