import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Logo from '../../Images/hero-float-img.png';
import AddNewMedicineModal from '../Suppliers/AddNewMedicineModal';
import { supabase } from '../../utils/supabase'; 

const Side = styled.div`
  background-color: #2c3e50;
  color: white;
  width: 240px;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #34495e;
    transform: translateX(10px);
  }

  &:active {
    background-color: #1abc9c;
  }
`;

const Img = styled.img`
    width: 150px;
    cursor: pointer;
`;

const ImageLink = styled(Link)`
    margin: 30px auto;
    border-radius: 2rem;
    padding: 10px;
    background: rgb(253, 255, 0);
    background-image: linear-gradient(to right, #ff8177 0%, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%, #b12a5b 100%);
`;

const Sidebar = ({ type }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newMedicine, setNewMedicine] = useState({ name: '', manufacturer: '', price: '', quantity: '' });

    const handleAddNewMedicine = async () => {
        try {
            const { error } = await supabase
                .from('medicines')
                .insert([{ name: newMedicine.name, manufacturer: newMedicine.manufacturer }]);
            
            if (error) {
                throw error;
            }

        } catch (error) {
            console.error('Error adding new medicine:', error.message);
        }
        // Close the modal after adding medicine
        setIsModalOpen(false);
        setNewMedicine({ name: '', manufacturer: '', price: '', quantity: '' });
    };

    return (
        <Side>
            <ImageLink to="/">
                <Img src={Logo} alt="logo" />
            </ImageLink>
            {type === 'supplier' ? (
                <>
                    <StyledLink to="#" onClick={() => setIsModalOpen(true)}>Add New Medicine</StyledLink>
                    <StyledLink to="/suppliers">Check New Orders</StyledLink>
                    <StyledLink to="/suppliers/accepted-orders">Accepted Orders</StyledLink>
                    <StyledLink to="/suppliers/order-history">Order History</StyledLink>
                </>
            ) : (<>
                <StyledLink to="/pos/">POS</StyledLink>
                <StyledLink to="/pos/orders">Orders</StyledLink>
                <StyledLink to="/pos/stock">Stock</StyledLink>
                <StyledLink to="/pos/purchase">Purchase Orders</StyledLink>
                <StyledLink to="/pos/medicines">All Medicines</StyledLink>
            </>)}
            <AddNewMedicineModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAddMedicine={handleAddNewMedicine}
                newMedicine={newMedicine}
                setNewMedicine={setNewMedicine}
            />
        </Side>
    );
};

export default Sidebar;
