// src/Components/Navbar.js
import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from "@auth0/auth0-react";

const Nav = styled.nav`
  background-color: #333;
  color: white;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div.pharmacyName{
    color: white;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
  }
`;

const Navbar = ({ type }) => {
    const { user, loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0(); return (
        <Nav>
            <div className='pharmacyName'>
                {type === 'supplier' ? 'MediTracker Supplier' : 'MediTracker Pharmacy'}
            </div>
            {!isAuthenticated ? (
                <div className="log-sign">
                    <button onClick={() => loginWithRedirect()} className="btn transparent">Login</button>
                    <button onClick={() => loginWithRedirect()} className="btn solid">Register</button>
                </div>
            ) : (
                <div className="log-sign">
                    <div className="logged-user-details">
                        <img src={user.picture} alt={user.name} />
                        <div className="logged-text">
                            <h2>{user.name}</h2>
                            <button onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
                        </div>
                    </div>
                </div>
            )}
        </Nav>
    );
};

export default Navbar;
