// src/Pages/Suppliers/Orders.jsx
import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../../utils/supabase';
import OrderTable from '../../Components/Suppliers/OrderTable';
import SupplierContext from '../../Components/Suppliers/SupplierContext';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const supplierId = 1; //useContext(SupplierContext); // Get the current supplier ID from context

    useEffect(() => {
        const fetchOrders = async () => {
            if (!supplierId) return;

            const { data, error } = await supabase
                .from('orders')
                .select('*, pharmacies(name), medicines(name)')
                .eq('status', 'Pending');

            if (error) {
                console.error('Error fetching orders:', error.message);
            } else {
                const formattedOrders = data.map(order => ({
                    id: order.id,
                    pharmacy_name: order.pharmacies.name,
                    medicine_name: order.medicines.name,
                    order_date: order.order_date,
                    order_quantity: order.order_quantity,
                    status: order.status,
                }));
                setOrders(formattedOrders);
            }
        };

        fetchOrders();

        const orderSubscription = supabase
            .channel('orders_channel')
            .on('postgres_changes', { event: '*', schema: 'public', table: 'orders' }, payload => {
                fetchOrders(); // Refetch orders when any change occurs
            })
            .subscribe();

        return () => {
            supabase.removeChannel(orderSubscription);
        };
    }, [supplierId]);

    const handleAccept = async (orderId) => {
        const { error } = await supabase
            .from('orders')
            .update({ status: 'Accepted', supplier_id: supplierId })
            .eq('id', orderId);

        if (error) {
            console.error('Error accepting order:', error.message);
        }
    };



    return (
        <div className='pos_container'>
            <h1>Available Orders</h1>
            <OrderTable orders={orders} onAccept={handleAccept}  />
        </div>
    );
};

export default Orders;
