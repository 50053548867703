import React from 'react';
import styled from 'styled-components';

// Styled Components
const FooterContainer = styled.footer`
  background-color: #1a1a1a; // Dark background color
  padding: 10px;
  text-align: center;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  color: #f8f9fa; // Light text color
  transform: translateY(-100%);
`;

const FooterText = styled.p`
  margin: 0;
  font-size: 16px;

  a {
    color: #f8f9fa; // Light link color
    text-decoration: none;
    font-weight: bold; // Highlight the name

    &:hover {
      text-decoration: underline;
    }
  }
`;

// Footer Component
const Footer = () => {
    return (
        <FooterContainer>
            <FooterText>
                Developed by <a href="https://induwara.dev/" target="_blank" rel="noopener noreferrer">Induwara Uthsara</a>
            </FooterText>
        </FooterContainer>
    );
};

export default Footer;
