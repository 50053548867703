import React from 'react'
import styled from 'styled-components';

const AdBannerComponent = styled.div`
background-color: #ececec;
color: #000;
border: 1px solid #ddd;
padding: 15px;
margin: 20px auto;
border-radius: 5px;
text-align: center;
font-size: 1.2em;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
width: 90%;
`;

export default function AdBanner() {
  return (
      <AdBannerComponent>
          📢 Your Advertisement Here! <br />
         for more details contact us via <a href="mailto:induwarauthsara@gmail.com"> Email </a>
      </AdBannerComponent>
  )
}
