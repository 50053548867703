import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../../utils/supabase'; // Import the supabase client
import PharmacyContext from '../../Components/Pos/PharmacyContext'; // Import PharmacyContext

const Pos = () => {
    const [medicines, setMedicines] = useState([]);
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newMedicine, setNewMedicine] = useState({ name: '', manufacturer: '', price: '', quantity: '' });
    const pharmacy_id = 1; //useContext(PharmacyContext); // Get the current pharmacy ID from context

    useEffect(() => {
        const fetchAndSubscribe = async () => {
            // Fetch initial medicines data for this pharmacy
            const { data, error } = await supabase
                .from('stock')
                .select('id, quantity, price, medicines(name)')
                .eq('pharmacy_id', pharmacy_id);

            if (error) {
                console.error('Error fetching medicines:', error.message);
            } else {
                const formattedData = data.map(item => ({
                    id: item.id,
                    quantity: item.quantity,
                    price: item.price,
                    name: item.medicines.name,
                }));
                setMedicines(formattedData);
            }

            // Subscribe to changes in the stock table
            const stockChannel = supabase
                .channel('custom-stock-channel')
                .on(
                    'postgres_changes',
                    { event: '*', schema: 'public', table: 'stock' },
                    async (payload) => {
                        const { eventType, new: newStockData, old: oldStockData } = payload;

                        if (eventType === 'INSERT' || eventType === 'UPDATE') {
                            const { data: fullData, error } = await supabase
                                .from('stock')
                                .select('id, quantity, price, medicines(name)')
                                .eq('id', newStockData.id)
                                .single();

                            if (error) {
                                console.error('Error fetching updated stock details:', error.message);
                                return;
                            }

                            setMedicines((prev) => {
                                switch (eventType) {
                                    case 'INSERT':
                                        return [...prev, {
                                            id: fullData.id,
                                            quantity: fullData.quantity,
                                            price: fullData.price,
                                            name: fullData.medicines.name,
                                        }];
                                    case 'UPDATE':
                                        return prev.map((med) =>
                                            med.id === fullData.id ? {
                                                id: fullData.id,
                                                quantity: fullData.quantity,
                                                price: fullData.price,
                                                name: fullData.medicines.name,
                                            } : med
                                        );
                                    default:
                                        return prev;
                                }
                            });
                        } else if (eventType === 'DELETE') {
                            setMedicines((prev) =>
                                prev.filter((med) => med.id !== oldStockData.id)
                            );
                        }
                    }
                )
                .subscribe();

            // Subscribe to changes in the medicines table
            const medicinesChannel = supabase
                .channel('medicines-channel')
                .on(
                    'postgres_changes',
                    { event: '*', schema: 'public', table: 'medicines' },
                    async (payload) => {
                        const { eventType, new: newMedicineData, old: oldMedicineData } = payload;

                        if (eventType === 'UPDATE') {
                            setMedicines((prev) =>
                                prev.map((med) =>
                                    med.medicine_id === newMedicineData.id ? {
                                        ...med,
                                        name: newMedicineData.name,
                                    } : med
                                )
                            );
                        } else if (eventType === 'DELETE') {
                            setMedicines((prev) =>
                                prev.filter((med) => med.medicine_id !== oldMedicineData.id)
                            );
                        }
                    }
                )
                .subscribe();

            return () => {
                supabase.removeChannel(stockChannel);
                supabase.removeChannel(medicinesChannel);
            };
        };

        if (pharmacy_id) {
            fetchAndSubscribe();
        }
    }, [pharmacy_id]);


    const addToCart = (medicine) => {
        const existingItem = cart.find(item => item.name === medicine.name);
        if (existingItem) {
            if (existingItem.quantity < medicine.quantity) {
                const updatedCart = cart.map(item =>
                    item.name === medicine.name
                        ? { ...item, quantity: item.quantity + 1 }
                        : item
                );
                setCart(updatedCart);
            } else {
                alert('Cannot add more than available quantity.');
            }
        } else {
            if (medicine.quantity > 0) {
                setCart([...cart, { ...medicine, quantity: 1 }]);
            } else {
                alert('Cannot add item with zero quantity.');
            }
        }
        updateTotal();
    };

    useEffect(() => {
        updateTotal();
    }, [cart]);

    const updateTotal = () => {
        const newTotal = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
        setTotal(newTotal);
    };

    const removeFromCart = (medicine) => {
        const updatedCart = cart.filter(item => item.name !== medicine.name);
        setCart(updatedCart);
        updateTotal();
    };

    const handlePurchase = async () => {
        if (cart.length === 0) {
            alert('Your cart is empty. Add some medicines before purchasing.');
            return;
        }

        for (const item of cart) {
            const { data: medicineData, error: fetchError } = await supabase
                .from('stock')
                .select('quantity')
                .eq('id', item.id)
                .single();

            if (fetchError) {
                console.error('Error fetching medicine quantity:', fetchError.message);
                continue;
            }

            const newQuantity = medicineData.quantity - item.quantity;

            const { error } = await supabase
                .from('stock')
                .update({ quantity: newQuantity })
                .eq('id', item.id);

            if (error) {
                console.error('Error updating medicine quantity:', error.message);
            }
        }

        alert('Order has been dispatched!');
        setCart([]);
        setTotal(0);
    };

    const handleAddNewMedicine = async () => {
        try {
            // Check if the medicine already exists in the medicines table
            const { data: existingMedicine, fetchError } = await supabase
                .from('medicines')
                .select('id')
                .eq('name', newMedicine.name)
                .limit(1)
                .maybeSingle();
            
            let medicine_id;
            
            if (fetchError) {
                // Error fetching existing medicine
                console.error('Error checking for existing medicine:', error.message);
            } else if (existingMedicine) {
                // If the medicine already exists, use the existing ID
                medicine_id = existingMedicine.id;
            } else {
                // If the medicine doesn't exist, insert it into the medicines table
                const { data: insertedMedicine, error: insertError } = await supabase
                    .from('medicines')
                    .insert([{ name: newMedicine.name, manufacturer: newMedicine.manufacturer }])
                    .select('id')
                    .single();

                if (insertError) throw insertError;
                medicine_id = insertedMedicine.id;
            }

            // Insert the new medicine into the stock table
            const { error } = await supabase
                .from('stock')
                .insert([{ medicine_id, pharmacy_id, price: newMedicine.price, quantity: newMedicine.quantity }]);

            if (error) throw error;

            setIsModalOpen(false);
            setNewMedicine({ name: '', manufacturer: '', price: '', quantity: '' });
        } catch (error) {
            console.error('Error adding new medicine:', error.message);
        }
    };

    const filteredMedicines = medicines.filter(medicine =>
        medicine.name && medicine.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isModalOpen && event.target.className === 'modal') {
                setIsModalOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isModalOpen]);

    return (
        <div className="pos_container">
            <h1>MediTracker Pharmacy POS</h1>
            <button className="add-medicine-btn" onClick={() => setIsModalOpen(true)}>Add New Medicine</button>
            <div className="main-content">
                <div className="cart-section">
                    <h2>Cart</h2>
                    {cart.length === 0 ? (
                        <p>Your cart is empty.</p>
                    ) : (
                        cart.map((item, index) => (
                            <div key={index} className="cart-item">
                                <span>{item.name}</span>
                                <span>Qty: {item.quantity}</span>
                                <span>Total: Rs.{(item.price * item.quantity).toFixed(2)}</span>
                                <button className="remove-item-btn" onClick={() => removeFromCart(item)}>X</button>
                            </div>
                        ))
                    )}
                    <h3>Total: Rs.{total.toFixed(2)}</h3>
                    <button
                        className={`purchase-button ${cart.length === 0 ? 'disabled' : ''}`}
                        onClick={handlePurchase}
                        disabled={cart.length === 0}
                    >
                        Purchase
                    </button>
                </div>

                <div className="medicine-section">
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search medicines..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>

                    <div className="medicines-list">
                        {filteredMedicines.map((medicine, index) => (
                            <div key={index} className="medicine-item">
                                <span>{medicine.name}</span>
                                <span>Rs.{medicine.price.toFixed(2)}</span>
                                <span>Available: {medicine.quantity}</span>
                                <button
                                    onClick={() => addToCart(medicine)}
                                    disabled={medicine.quantity < 0 || cart.some(item => item.name === medicine.name && item.quantity >= medicine.quantity)}
                                >
                                    Add to Cart
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Add New Medicine</h2>
                        <input
                            type="text"
                            placeholder="Medicine Name"
                            list="medicine-list" // HTML5 input suggestion
                            value={newMedicine.name}
                            onChange={(e) => setNewMedicine({ ...newMedicine, name: e.target.value })}
                        />
                        <datalist id="medicine-list">
                            {medicines.map((medicine, index) => (
                                <option key={index} value={medicine.name} />
                            ))}
                        </datalist>
                        <input
                            type="text"
                            placeholder="Manufacturer"
                            value={newMedicine.manufacturer}
                            onChange={(e) => setNewMedicine({ ...newMedicine, manufacturer: e.target.value })}
                        />
                        <input
                            type="number"
                            placeholder="Price"
                            value={newMedicine.price}
                            onChange={(e) => setNewMedicine({ ...newMedicine, price: parseFloat(e.target.value) })}
                        />
                        <input
                            type="number"
                            placeholder="Quantity"
                            value={newMedicine.quantity}
                            onChange={(e) => setNewMedicine({ ...newMedicine, quantity: parseInt(e.target.value, 10) })}
                        />
                        <button onClick={handleAddNewMedicine}>Add Medicine</button>
                        <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Pos;
