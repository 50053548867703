import React from 'react';
import styled from 'styled-components';

const H2 = styled.h2`
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
`;

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 4px;
    width: 300px;
`;

const Buttons = styled.div`
    justify-content: space-between;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    
    button {
        width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;}
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;

`;

const AddNewMedicineModal = ({ isOpen, onClose, onAddMedicine, newMedicine, setNewMedicine }) => {
    if (!isOpen) return null;

    return (
        <ModalBackdrop>
            <ModalContent>
                <H2>Add New Medicine</H2>
                <Input
                    type="text"
                    placeholder="Medicine Name"
                    value={newMedicine.name}
                    onChange={(e) => setNewMedicine({ ...newMedicine, name: e.target.value })}
                />
                <Input
                    type="text"
                    placeholder="Manufacturer"
                    value={newMedicine.manufacturer}
                    onChange={(e) => setNewMedicine({ ...newMedicine, manufacturer: e.target.value })}
                />
                <Input
                    type="number"
                    placeholder="Price"
                    value={newMedicine.price}
                    onChange={(e) => setNewMedicine({ ...newMedicine, price: parseFloat(e.target.value) })}
                />
                <Input
                    type="number"
                    placeholder="Quantity"
                    value={newMedicine.quantity}
                    onChange={(e) => setNewMedicine({ ...newMedicine, quantity: parseInt(e.target.value, 10) })}
                />
                <Buttons>
                <button onClick={onAddMedicine}>Add Medicine</button>
                    <button style={{ 'background-color':'#dc3545' }} onClick={onClose}>Cancel</button>
                </Buttons>
            </ModalContent>
        </ModalBackdrop>
    );
};

export default AddNewMedicineModal;
