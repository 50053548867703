import React from 'react'
import StockTable from '../../Components/Pos/StockTable'

export default function Stock() {
    return (
        <div className="pos_container">
            <h1>Stock</h1>
            <StockTable />
        </div>
    )
}
