import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { supabase } from '../utils/supabase';
import './Scheduler.css';
import AdBanner from '../Components/AdBanner';

// Styled components for alert message
const AlertMessage = styled.div`
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 15px;
    margin: 20px 0;
    border-radius: 5px;
    text-align: center;
    font-size: 1.1em;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const Scheduler = () => {
    const { isAuthenticated, getIdTokenClaims } = useAuth0();
    const [show, setShow] = useState(false);
    const [viewShow, setViewShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [nextDoseShow, setNextDoseShow] = useState(false);
    const [currentSchedule, setCurrentSchedule] = useState(null);
    const [firstDoseTime, setFirstDoseTime] = useState('');
    const [interval, setInterval] = useState(8);
    const [remindTimes, setRemindTimes] = useState(1);
    const [customTimes, setCustomTimes] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [nextDosesMap, setNextDosesMap] = useState({});
    const [userID, setUserID] = useState(null);

    // Fetch User ID from Auth0
    useEffect(() => {
        const fetchUserID = async () => {
            if (isAuthenticated) {
                const claims = await getIdTokenClaims();
                setUserID(claims.sub);
            }
        };

        fetchUserID();
    }, [isAuthenticated, getIdTokenClaims]);

    // Fetch schedules and their next doses from Supabase
    useEffect(() => {
        const fetchSchedulesAndNextDoses = async () => {
            const { data: schedulesData, error: schedulesError } = await supabase
                .from('medicine_schedulers')
                .select('*')
                .eq('user_id', userID);

            if (schedulesError) {
                console.error('Error fetching schedules:', schedulesError.message);
            } else {
                setSchedules(schedulesData || []);

                // Fetch next doses for each schedule
                const nextDosesPromises = schedulesData.map(async (schedule) => {
                    const currentTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Colombo' });
                    console.log('Current Time:', currentTime);

                    const { data: nextDosesData, error: nextDosesError } = await supabase
                        .from('next_doses')
                        .select('*, scheduler_id')
                        .gte('next_dose_time', currentTime) // Get only future doses
                        .order('id', { ascending: true })
                        .eq('scheduler_id', schedule.id);
                    // .groupBy('scheduler_id, next_dose_time');

                    if (nextDosesError) {
                        console.error('Error fetching next doses:', nextDosesError.message);
                    } else {
                        console.log('Next Doses:', nextDosesData);
                        return { [schedule.id]: nextDosesData || [] };
                    }
                });

                const nextDosesArray = await Promise.all(nextDosesPromises);
                const nextDosesObject = Object.assign({}, ...nextDosesArray);
                setNextDosesMap(nextDosesObject);
            }
        };

        if (userID) {
            fetchSchedulesAndNextDoses();
        }
    }, [userID, isAuthenticated]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleViewClose = () => setViewShow(false);
    const handleViewShow = (schedule) => {
        setCurrentSchedule(schedule);
        setViewShow(true);
    };

    const handleEditClose = () => setEditShow(false);
    const handleEditShow = (schedule) => {
        setCurrentSchedule(schedule);
        setFirstDoseTime(schedule.first_dose_time);
        setCustomTimes(schedule.custom_times || []);
        setInterval(schedule.dose_period);
        setRemindTimes(schedule.remind_times || 1);
        setEditShow(true);
    };

    const handleDeleteClose = () => setDeleteShow(false);
    const handleDeleteShow = (schedule) => {
        setCurrentSchedule(schedule);
        setDeleteShow(true);
    };

    const handleNextDoseClose = () => setNextDoseShow(false);
    const handleNextDoseShow = (scheduleId) => {
        setCurrentSchedule(schedules.find(schedule => schedule.id === scheduleId));
        setNextDoseShow(true);
    };

    const handleAddSchedule = async (event) => {
        event.preventDefault();
        const calculatedTimes = calculateTimes(firstDoseTime, interval, remindTimes);
        const allTimes = [...calculatedTimes, ...customTimes];

        const { data, error } = await supabase
            .from('medicine_schedulers')
            .insert([{
                user_id: userID,
                scheduler_name: event.target.elements.formSchedulerName.value,
                medicine_name: event.target.elements.formMedicineName.value,
                dose_period: interval,
                description: event.target.elements.formDescription.value,
                custom_times: customTimes,
                remind_times: remindTimes,
                first_dose_time: firstDoseTime
            }])
            .select();

        if (error) {
            console.error('Error adding schedule:', error.message);
        } else {
            const schedulerId = data[0].id;
            await saveNextDoses(schedulerId, allTimes);
            setSchedules([...schedules, data[0]]);
            handleClose();
        }
    };

    const calculateTimes = (startTime, interval, remindTimes) => {
        const times = [];
        let initialTime = new Date(startTime);

        for (let i = 0; i < remindTimes; i++) {
            initialTime.setTime(initialTime.getTime() + interval * 60 * 60 * 1000);
            times.push(initialTime.toISOString());
        }

        return times;
    };

    const saveNextDoses = async (schedulerId, times) => {
        const nextDoses = times.map((time, index) => ({
            scheduler_id: schedulerId,
            next_dose_time: time,
            reminder_count: index + 1
        }));

        const { error } = await supabase
            .from('next_doses')
            .insert(nextDoses);

        if (error) {
            console.error('Error saving next doses:', error.message);
        } else {
            setNextDosesMap(prev => ({
                ...prev,
                [schedulerId]: nextDoses
            }));
        }
    };

    const handleEditSchedule = async (event) => {
        event.preventDefault();

        const calculatedTimes = calculateTimes(firstDoseTime, interval, remindTimes);
        const allTimes = [...calculatedTimes, ...customTimes];

        const { data, error } = await supabase
            .from('medicine_schedulers')
            .update({
                scheduler_name: event.target.elements.formSchedulerName.value,
                medicine_name: event.target.elements.formMedicineName.value,
                dose_period: interval,
                description: event.target.elements.formDescription.value,
                custom_times: customTimes,
                remind_times: remindTimes,
                first_dose_time: firstDoseTime
            })
            .eq('id', currentSchedule.id)
            .select();

        if (error) {
            console.error('Error updating schedule:', error.message);
        } else {
            await supabase
                .from('next_doses')
                .delete()
                .eq('scheduler_id', currentSchedule.id);

            await saveNextDoses(currentSchedule.id, allTimes);

            const updatedSchedule = {
                ...data[0],
                custom_times: data[0].custom_times || []
            };

            setSchedules(schedules.map(schedule =>
                schedule.id === currentSchedule.id ? updatedSchedule : schedule
            ));
            handleEditClose();
        }
    };

    const handleDeleteSchedule = async () => {
        const { error } = await supabase
            .from('medicine_schedulers')
            .delete()
            .eq('id', currentSchedule.id);

        if (error) {
            console.error('Error deleting schedule:', error.message);
        } else {
            await supabase
                .from('next_doses')
                .delete()
                .eq('scheduler_id', currentSchedule.id);

            setSchedules(schedules.filter(schedule => schedule.id !== currentSchedule.id));
            setNextDosesMap(prev => {
                const { [currentSchedule.id]: removed, ...rest } = prev;
                return rest;
            });
            handleDeleteClose();
        }
    };

    const addCustomTime = () => {
        setCustomTimes([...customTimes, '']);
    };

    const updateCustomTime = (index, value) => {
        const updatedTimes = customTimes.map((time, i) => (i === index ? value : time));
        setCustomTimes(updatedTimes);
    };

    const removeCustomTime = (index) => {
        const updatedTimes = customTimes.filter((_, i) => i !== index);
        setCustomTimes(updatedTimes);
    };


    const sendNotification = (title, body) => {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.ready.then(function (registration) {
                registration.showNotification(title, {
                    body: body,
                    icon: 'icon.png',
                    data: { url: '/your-app-url' } // Provide a URL to open on click
                });
            });
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = new Date().toISOString();
            for (const scheduleId in nextDosesMap) {
                const nextDoses = nextDosesMap[scheduleId];
                if (nextDoses && nextDoses.length > 0) {
                    const nextDose = nextDoses[0];
                    if (nextDose.next_dose_time <= currentTime) {
                        sendNotification(
                            'Time to take your medicine!',
                            `It's time to take your dose for ${schedules.find(s => s.id === scheduleId)?.medicine_name}.`
                        );
                        setNextDosesMap(prev => ({
                            ...prev,
                            [scheduleId]: prev[scheduleId].slice(1)
                        }));
                    }
                }
            }
        }, 60000); // Check every minute

        return () => clearInterval(intervalId);
    }, [nextDosesMap, schedules]);

    return (
        <div className="container mt-5 d-flex flex-column">
            {console.log("isAuthenticated", !isAuthenticated)}
            {!isAuthenticated && (
                <>
                    <AlertMessage>
                        🚨 Please log in to use this feature. Log in to schedule and track your medicine doses easily.
                    </AlertMessage>
                    <AdBanner />
                </>
            )}
            {isAuthenticated && (
                <>
                        <AdBanner />
                    <div className="title d-flex justify-content-between">
                        <Button variant="success" className="mb-4" style={{ width: '150px', fontSize: '1.3rem' }} onClick={handleShow}>
                            Add New
                        </Button>
                    </div>

                    {schedules.length > 0 ? (
                        schedules.map((schedule) => (
                            <div className="card mb-3" key={schedule.id}>
                                <div className="card-body d-flex justify-content-between">
                                    <div className="text">
                                        <h3 className="card-title">{schedule.scheduler_name}</h3>
                                        <p className="card-text"><strong>Next Dose - {nextDosesMap[schedule.id] && nextDosesMap[schedule.id][0] ? new Date(nextDosesMap[schedule.id][0].next_dose_time).toLocaleString() : 'No dose scheduled'}</strong> <span role="img" aria-label="bell">🔔</span></p>
                                        <p className="card-text"><strong>Upcoming Dose - {nextDosesMap[schedule.id] && nextDosesMap[schedule.id][1] ? new Date(nextDosesMap[schedule.id][1].next_dose_time).toLocaleString() : 'No upcoming dose'}</strong></p>
                                        <p className="card-text">Medicine : {schedule.medicine_name}</p>
                                        <p className="card-text">Description : {schedule.description}</p>
                                    </div>
                                    <div className="buttons d-flex flex-column justify-content-evenly">
                                        <Button variant="primary" className="me-2" onClick={() => handleViewShow(schedule)}>View</Button>
                                        <Button variant="warning" className="me-2" onClick={() => handleEditShow(schedule)}>Edit</Button>
                                        <Button variant="danger" onClick={() => handleDeleteShow(schedule)}>Delete</Button>
                                        <Button variant="info" className="me-2" onClick={() => handleNextDoseShow(schedule.id)}>Next Doses</Button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center mt-5 mb-5 fs-4">No scheduled medicines found. <br /> Click <b>"Add New"</b> to create a schedule.</div>
                    )}
                </>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Scheduler</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleAddSchedule}>
                        <Form.Group className="mb-3" controlId="formSchedulerName">
                            <Form.Label>Scheduler Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter scheduler name" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formMedicineName">
                            <Form.Label>Medicine Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter medicine name" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formFirstDose">
                            <Form.Label>First Dose Time</Form.Label>
                            <input type="datetime-local" className='ms-3 p-2' value={firstDoseTime} onChange={(e) => setFirstDoseTime(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formInterval">
                            <Form.Label>Interval (hours)</Form.Label>
                            <Form.Control type="number" min="1" max="24" value={interval} onChange={(e) => setInterval(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formRemindTimes">
                            <Form.Label>How many times you want to repeat this Scheduler?</Form.Label>
                            <Form.Control type="number" min="1" max="20" value={remindTimes} onChange={(e) => setRemindTimes(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formCustomTimes">
                            <Form.Label>Custom Times (Optional)</Form.Label>
                            {customTimes.map((time, index) => (
                                <div key={index} className="d-flex mb-2">
                                    <input type="datetime-local" className='ms-3 p-2' value={time} onChange={(e) => updateCustomTime(index, e.target.value)} required />
                                    <Button variant="danger" className="ms-2" style={{ width: '150px' }} onClick={() => removeCustomTime(index)}>Remove</Button>
                                </div>
                            ))}
                            <Button variant="secondary" onClick={addCustomTime} style={{ marginLeft: '5px' }}>Add Custom Time</Button>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter description" required />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Create
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={viewShow} onHide={handleViewClose}>
                <Modal.Header closeButton>
                    <Modal.Title>View Scheduler</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentSchedule && (
                        <div>
                            <p><strong>Name:</strong> {currentSchedule.scheduler_name}</p>
                            <p><strong>Medicine:</strong> {currentSchedule.medicine_name}</p>
                            <p><strong>First Dose:</strong> {new Date(currentSchedule.first_dose_time).toLocaleString()}</p>
                            <p><strong>Interval:</strong> {currentSchedule.dose_period} hours</p>
                            <p><strong>Remind Times:</strong> {currentSchedule.remind_times}</p>
                            <p><strong>Custom Times:</strong> {currentSchedule.custom_times && currentSchedule.custom_times.length > 0 ? currentSchedule.custom_times.map(time => new Date(time).toLocaleString()).join(', ') : 'No custom times'}</p>

                            <p><strong>Next Dose:</strong> {nextDosesMap[currentSchedule.id] && nextDosesMap[currentSchedule.id][0] ? new Date(nextDosesMap[currentSchedule.id][0].next_dose_time).toLocaleString() : 'No dose scheduled'}</p>
                            <p><strong>Upcoming Dose:</strong> {nextDosesMap[currentSchedule.id] && nextDosesMap[currentSchedule.id][1] ? new Date(nextDosesMap[currentSchedule.id][1].next_dose_time).toLocaleString() : 'No upcoming dose'}</p>
                            <p><strong>Description:</strong> {currentSchedule.description}</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleViewClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={editShow} onHide={handleEditClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Scheduler</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentSchedule && (
                        <Form onSubmit={handleEditSchedule}>
                            <Form.Group className="mb-3" controlId="formSchedulerName">
                                <Form.Label>Scheduler Name</Form.Label>
                                <Form.Control type="text" defaultValue={currentSchedule.scheduler_name} required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formMedicineName">
                                <Form.Label>Medicine Name</Form.Label>
                                <Form.Control type="text" defaultValue={currentSchedule.medicine_name} required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formFirstDose">
                                <Form.Label>First Dose Time</Form.Label>
                                <input type="datetime-local" className='ms-3  p-2' value={firstDoseTime} onChange={(e) => setFirstDoseTime(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formInterval">
                                <Form.Label>Interval (hours)</Form.Label>
                                <Form.Control type="number" min="1" max="24" value={interval} onChange={(e) => setInterval(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formRemindTimes">
                                <Form.Label>How many times you want to be reminded?</Form.Label>
                                <Form.Control type="number" min="1" max="20" value={remindTimes} onChange={(e) => setRemindTimes(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formCustomTimes">
                                <Form.Label>Custom Times (Optional)</Form.Label>
                                {customTimes.map((time, index) => (
                                    <div key={index} className="d-flex mb-2">
                                        <input type="datetime-local" className='ms-3  p-2' value={time} onChange={(e) => updateCustomTime(index, e.target.value)} required />
                                        <Button variant="danger" className="ms-2" style={{ width: '150px' }} onClick={() => removeCustomTime(index)}>Remove</Button>
                                    </div>
                                ))}
                                <Button variant="secondary" onClick={addCustomTime}>Add Custom Time</Button>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={3} defaultValue={currentSchedule.description} required />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>
                        </Form>
                    )}
                </Modal.Body>
            </Modal>

            <Modal show={deleteShow} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Scheduler</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the scheduler "{currentSchedule?.scheduler_name}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteSchedule}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={nextDoseShow} onHide={handleNextDoseClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Next Doses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        nextDosesMap[currentSchedule?.id] && nextDosesMap[currentSchedule?.id].length > 0 ? (
                            nextDosesMap[currentSchedule?.id]
                                .sort((a, b) => new Date(a.next_dose_time) - new Date(b.next_dose_time)) // Sort by next_dose_time
                                .map((dose, index) => (
                                    <div key={index}>
                                        <p><strong>Dose {index + 1}:</strong> {new Date(dose.next_dose_time).toLocaleString()}</p>
                                    </div>
                                ))
                        ) : (
                            <p>No Scheduled doses found.</p>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleNextDoseClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Scheduler;
