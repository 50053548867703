import React from "react";
import "./home.css";
import Typewriter from "typewriter-effect";
import logo from "../logo.png";
import heroimg from "../Images/hero-float-img.png";
import mobileimg01 from "../Images/mobile-display02.png";
import mobileimg02 from "../Images/mobile-display01.png";

import Pharmacy_img from "../Images/Pharmacy POV.webp";
import Supplier_img from "../Images/Supplier POV.webp";
import User_img from "../Images/User  POV.webp";
import user_testimonial from "../Images/Testimomonial-users.png";
import pharmacy_testimonial from "../Images/Testimonial-pharmacist.png";
import supplier_testimonial from "../Images/Testimonial-supplier.png";

import noticeimg01 from "../Images/block-image01.png";
import noticeimg02 from "../Images/block-image02.png";
import noticeimg03 from "../Images/block-image03.png";
import { useAuth0, User } from "@auth0/auth0-react";
import { FaCaretRight } from "react-icons/fa";
import { SlLogin } from "react-icons/sl";

import { Link } from "react-router-dom";

export default function Home() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  return (
    <>
      <div>
        <div className="hero">
          <img src={heroimg} className="logo" alt="Medi-Track Logo" />
          <div className="hero-txt">
            <h1>
              MediTrack
              <span> helps to</span>
              <Typewriter
                className="typing"
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: [
                    "Track medicine availability and never miss a dose.",
                    "Schedule reminders and ensure you never miss a dose.",
                    "Work with busy schedules without worrying about medical problems.",
                    "Track the stock status in few clicks",
                    "Receive timely reminders and stay informed about medicine schedules",
                  ],
                }}
              />
            </h1>
            <p className="hero-para">
              MediTrack is a user-friendly web application that simplifies the
              process of finding medication and help people to Solve health and
              medical problems. Whether you’re a senior citizen, a caregiver, or
              someone with a busy schedule, MediTrack ensures you never miss a
              dose.
            </p>
            <div className="hero-buttons">
              {!isAuthenticated && (
                <button onClick={() => loginWithRedirect()} id="get-login-btn">
                  Get Started
                </button>
              )}
              <Link
                to="https://meditracker.blogspot.com/"
                className="hero-about-button"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="card">
            <img src={User_img} alt="User-System" />
            <div className="card-content">
              <h2>Customer</h2>
              <p>
                MediTracker helps you stay on top of your medication by
                providing reminders and locating nearby pharmacies.
              </p>
              {/*<Link to="#" className="card-button">
                User Login <SlLogin />
              </Link>*/}
            </div>
          </div>

          <div className="card">
            <img src={Supplier_img} alt="Supplier-System" />
            <div className="card-content">
              <h2>Medicine Supplier</h2>
              <p>
                Expand your reach and manage your supply chain with ease through
                MediTracker’s supplier platform.
              </p>
              {/*<Link to="#" className="card-button">
                Supplier Login <SlLogin />
              </Link>*/}
            </div>
          </div>

          <div className="card">
            <img src={Pharmacy_img} alt="pharmacy-pos" />
            <div className="card-content">
              <h2>Pharmacist</h2>
              <p>
                MediTracker: Streamlined POS, real-time inventory, increased
                customer satisfaction.
              </p>
              {/*<Link to="#" className="card-button">
                Pharmacist Login <SlLogin />
              </Link>*/}
            </div>
          </div>
        </div>

        <hr className="horizontal-line" />

        <div className="second-section">
          <p className="smalltext-second">Simplify</p>
          <h1 className="big-text">
            Track Medicine Availability with Ease
          </h1>{" "}
          <br />
          <p className="second-para">
            Our medicine availability tracker makes it easy to stay informed
            about the availability of your prescribed medications. With just a
            few clicks, you can track the stock status and receive timely
            reminders.
          </p>
          <br />
          <div className="dual-texts">
            <div /*className='info1'*/>
              <p className="infotitle">
                <strong>Stay Informed</strong>
              </p>
              <p className="infopara">
                Get real-time updates on medicine availability and never miss a
                dose again.
              </p>
            </div>
            <hr />
            <div /*className='info2'*/>
              <p className="infotitle">
                <strong>Set Reminders</strong>
              </p>
              <p className="infopara">
                Schedule reminders for taking your medications and never forget
                to take them.
              </p>
            </div>
          </div>
          {!isAuthenticated && (
            <div className="second-about-btn">
              <Link
                to="https://meditracker.blogspot.com/"
                className="second-about"
              >
                Learn More
              </Link>
              <button
                onClick={() => loginWithRedirect()}
                className="second-register"
              >
                Register <FaCaretRight />
              </button>
            </div>
          )}
        </div>

        <h1>What our Clients Say,</h1>

        <div className="container">
          <div className="testimonial-card">
            <img src={user_testimonial} alt="User-System" />
            <div className="testimonial-content">
              <h2>S. Liyanage</h2>
              <p className="testimonial-role">MediTrack User</p>
              <p className="testimonial-para">
              "Meditracker is a valuable healthcare tool that saves time searching for medications, helps schedule treatment, provides valuable information through AI chatbot, connects patients through a blog and forum, and allows easy online ordering for medication delivery. Highly recommend for patients"
              </p>
              {/*<Link to="#" className="card-button">
                User Login <SlLogin />
              </Link>*/}
            </div>
          </div>

          <div className="testimonial-card">
            <img src={pharmacy_testimonial} alt="Supplier-System" />
            <div className="testimonial-content">
              <h2>Y. Ranasinghe</h2>
              <p className="testimonial-role">Pharmacist</p>
              <p className="testimonial-para">
              "Meditracker has significantly improved pharmacy management by enhancing efficiency and user-friendliness. Its user-friendly POS system streamlines transactions, eliminates manual inventory tracking, and allows for direct ordering with suppliers, reducing stockout risks. Highly recommended."
              </p>
              {/*<Link to="#" className="card-button">
                Supplier Login <SlLogin />
              </Link>*/}
            </div>
          </div>

          <div className="testimonial-card">
            <img src={supplier_testimonial} alt="pharmacy-pos" />
            <div className="testimonial-content">
              <h2>M. Y. Perera</h2>
              <p className="testimonial-role">Medicine Supplier</p>
              <p className="testimonial-para">
              "Meditracker's supplier system has significantly improved our business efficiency and customer satisfaction by providing clear visibility into pharmacy orders, real-time inventory management, and timely deliveries, resulting in a significant improvement in our operations."
              </p>
              {/*<Link to="#" className="card-button">
                Pharmacist Login <SlLogin />
              </Link>*/}
            </div>
          </div>
        </div>

        <div className="third-section">
          <h1 className="big-text-title">
            Effortlessly manage your medication with our scheduling and reminder
            feature
          </h1>{" "}
          <br />
          <p className="second-para">
            Never miss a dose again. Our medicine availability tracker helps you
            stay on top of your medication schedule.
          </p>
          <br />
          <img src={mobileimg02} className="Mobile-img01" alt="view-img" />
          <div className="dual-texts-third">
            <div /*className='info1'*/>
              <p className="infotitle">
                <strong>Easy-to-use</strong>
              </p>
              <p className="infopara">
                Set up personalized medication schedules and receive reminders
                to take your medicine.
              </p>
            </div>
            <hr />
            <div /*className='info2'*/>
              <p className="infotitle">
                <strong>Convenient</strong>
              </p>
              <p className="infopara">
                Stay organized with our medication tracking system and never
                miss a dose again.
              </p>
            </div>
          </div>
        </div>

        {/**Card Type Notice Section */}

        <h1>Experience the Difference Today.</h1>

        <div className="container">
          <div className="card">
            <img src={noticeimg01} alt="notice-img" />
            <div className="card-content">
              <h2>Continuous Real-Time Updates to Keep You Well-Informed</h2>
              <p>
                Stay up-to-date with the latest information on medicine
                availability.
              </p>
            </div>
          </div>

          <div className="card">
            <img src={noticeimg02} alt="notice-img" />
            <div className="card-content">
              <h2>Secure Data Handling for Privacy and Peace of Mind.</h2>
              <p>Rest assured that your personal information is protected.</p>
            </div>
          </div>

          <div className="card">
            <img src={noticeimg03} alt="notice-img" />
            <div className="card-content">
              <h2>Medicine Scheduling and Reminders for Easy Management.</h2>
              <p>
                Never miss a dose with our convenient scheduling and reminder
                features.
              </p>
            </div>
          </div>
        </div>

        {!isAuthenticated && (
          <div className="ending-section">
            <hr className="featurette-divider" />
            <h1>Track Medicine Availability Easily</h1>
            <p>
              Never run out of medicine again with our availability tracker and
              scheduling system.
            </p>
            <div className="ending-buttons">
              <button
                onClick={() => loginWithRedirect()}
                className="hero-login-button"
              >
                Login
              </button>
              <button
                onClick={() => loginWithRedirect()}
                className="hero-about-button"
              >
                Register
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
