import React, { useEffect, useState, useContext } from 'react';
import { supabase } from '../../utils/supabase'; // Import the supabase client
import PharmacyContext from '../../Components/Pos/PharmacyContext'; // Adjust the import based on your setup

export default function CustomerOrders() {
    const [orders, setOrders] = useState([]);
    const pharmacyContext = useContext(PharmacyContext);
    const pharmacyId = pharmacyContext ? pharmacyContext.id : 1; // Use context to get the pharmacy ID

    useEffect(() => {
        const fetchOrders = async () => {
            // Fetch pharmacy_name from pharmacies table
            const { data: pharmacy_data, error: pharmacyError } = await supabase
                .from('pharmacies')
                .select('name')
                .eq('id', pharmacyId);

            if (pharmacyError) {
                console.error('Error fetching pharmacy:', pharmacyError.message);
                return;
            } else {
                const pharmacy_name = pharmacy_data[0].name;
                console.log(pharmacy_name);

                const { data, error } = await supabase
                    .from('customer_orders')
                    .select('*')
                    .eq('order_status', 'pending')
                    .eq('pharmacy_name', pharmacy_name);

                if (error) {
                    console.error('Error fetching orders:', error.message);
                } else {
                    setOrders(data);
                }
            }
        };

        fetchOrders();

        // Subscribe to changes in the customer_orders table
        const orderSubscription = supabase
            .channel('customer_orders_channel')
            .on('postgres_changes', { event: '*', schema: 'public', table: 'customer_orders' }, payload => {
                fetchOrders(); // Refetch orders when any change occurs
            })
            .subscribe();

        return () => {
            supabase.removeChannel(orderSubscription);
        };
    }, [pharmacyId]);

    const updateOrderStatus = async (orderId, newStatus) => {
        const { error } = await supabase
            .from('customer_orders')
            .update({ order_status: newStatus })
            .eq('id', orderId);

        if (error) {
            console.error(`Error updating order status to ${newStatus}:`, error.message);
        } else {
            // Refresh the order list after update
            const updatedOrders = orders.map(order =>
                order.id === orderId ? { ...order, order_status: newStatus } : order
            );
            setOrders(updatedOrders);
        }
    };

    return (
        <div className="pos_container">
            <h1>Customer Orders</h1>
            <table>
                <thead>
                    <tr>
                        <th>Medicine</th>
                        <th>Quantity</th>
                        <th>Delivery Type</th>
                        <th>Customer Name</th>
                        <th>Customer Tele</th>
                        <th>Description</th>
                        <th>Order Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <tr key={order.id}>
                            <td>{order.medicine}</td>
                            <td>{order.quantity}</td>
                            <td>{order.delivery_type}</td>
                            <td>{order.customer_name}</td>
                            <td>{order.customer_tele}</td>
                            <td>{order.description}</td>
                            <td>{new Date(order.created_at).toLocaleDateString()}</td>
                            <td>
                                {order.order_status === 'pending' && (
                                    <buttons>
                                        <button onClick={() => updateOrderStatus(order.id, 'completed')}>Complete</button> <br></br>
                                        <button className='danger' onClick={() => updateOrderStatus(order.id, 'rejected')}>Reject</button>
                                    </buttons>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
