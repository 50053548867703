// src/Components/Suppliers/OrderHistoryTable.jsx
import React from 'react';

const OrderHistoryTable = ({ orders, onReject, onComplete }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>Pharmacy Name</th>
                    <th>Medicine Name</th>
                    <th>Order Date</th>
                    <th>Order Quantity</th>
                    <th>Status</th>
                    <th>Supplier</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {orders.map(order => (
                    <tr key={order.id}>
                        <td>{order.pharmacy_name}</td>
                        <td>{order.medicine_name}</td>
                        <td>{new Date(order.order_date).toLocaleDateString()}</td>
                        <td>{order.order_quantity}</td>
                        <td>{order.status}</td>
                        <td>{order.supplier_name || 'N/A'}</td>
                        <td>
                            {order.status === 'Accepted' && (
                                <buttons>
                                    <button onClick={() => onReject(order.id)}>Reject</button> 
                                    <button onClick={() => onComplete(order.id)}>Complete</button>
                                </buttons>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default OrderHistoryTable;
