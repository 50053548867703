import React, { useEffect, useState, useContext } from 'react';
import { supabase } from '../../utils/supabase'; // Import the supabase client
import PharmacyContext from './PharmacyContext'; // Adjust the import based on your setup

const StockTable = () => {
    const [medicines, setMedicines] = useState([]);
    const [selectedMedicine, setSelectedMedicine] = useState(null);
    const [modalType, setModalType] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const pharmacy_id = 1; // useContext(PharmacyContext); // Get the current pharmacy ID from context

    useEffect(() => {
        const fetchStock = async () => {
            // Fetch medicines from the stock table, joining with the medicines table for details
            const { data, error } = await supabase
                .from('stock')
                .select('id, quantity, price, medicines(id, name, manufacturer)')
                .eq('pharmacy_id', pharmacy_id);

            if (error) {
                console.error('Error fetching stock:', error.message);
            } else {
                // Map the data to make it easier to work with in the UI
                const mappedData = data.map(stockItem => ({
                    id: stockItem.id,
                    quantity: stockItem.quantity,
                    price: stockItem.price,
                    name: stockItem.medicines.name,
                    manufacturer: stockItem.medicines.manufacturer,
                    medicine_id: stockItem.medicines.id, // Keep track of the medicine_id for updates and deletes
                }));
                setMedicines(mappedData);
            }

            // Subscribe to changes in the stock and medicines tables
            const stockChannel = supabase
                .channel('stock-changes')
                .on(
                    'postgres_changes',
                    { event: '*', schema: 'public', table: 'stock' },
                    async (payload) => {
                        const { eventType, new: newStockData, old: oldStockData } = payload;

                        switch (eventType) {
                            case 'INSERT': {
                                // Fetch the medicine details for the new stock entry
                                const { data: medicineData, error } = await supabase
                                    .from('medicines')
                                    .select('name, manufacturer')
                                    .eq('id', newStockData.medicine_id)
                                    .single();

                                if (error) {
                                    console.error('Error fetching medicine details:', error.message);
                                    return;
                                }

                                setMedicines((prev) => [
                                    ...prev,
                                    {
                                        id: newStockData.id,
                                        quantity: newStockData.quantity,
                                        price: newStockData.price,
                                        name: medicineData.name,
                                        manufacturer: medicineData.manufacturer,
                                        medicine_id: newStockData.medicine_id,
                                    },
                                ]);
                                break;
                            }
                            case 'UPDATE': {
                                if (newStockData.medicine_id !== oldStockData.medicine_id) {
                                    // Fetch the new medicine details if the medicine_id has changed
                                    const { data: medicineData, error } = await supabase
                                        .from('medicines')
                                        .select('name, manufacturer')
                                        .eq('id', newStockData.medicine_id)
                                        .single();

                                    if (error) {
                                        console.error('Error fetching updated medicine details:', error.message);
                                        return;
                                    }

                                    setMedicines((prev) =>
                                        prev.map((med) =>
                                            med.id === newStockData.id
                                                ? {
                                                    ...med,
                                                    name: medicineData.name,
                                                    manufacturer: medicineData.manufacturer,
                                                    quantity: newStockData.quantity,
                                                    price: newStockData.price,
                                                    medicine_id: newStockData.medicine_id,
                                                }
                                                : med
                                        )
                                    );
                                } else {
                                    // Handle updates where only quantity or price changed
                                    setMedicines((prev) =>
                                        prev.map((med) =>
                                            med.id === newStockData.id
                                                ? {
                                                    ...med,
                                                    quantity: newStockData.quantity,
                                                    price: newStockData.price,
                                                }
                                                : med
                                        )
                                    );
                                }
                                break;
                            }
                            case 'DELETE': {
                                setMedicines((prev) =>
                                    prev.filter((med) => med.id !== oldStockData.id)
                                );
                                break;
                            }
                            default:
                                break;
                        }
                    }
                )
                .subscribe();



            const medicinesChannel = supabase
                .channel('medicines-changes')
                .on(
                    'postgres_changes',
                    { event: '*', schema: 'public', table: 'medicines' },
                    (payload) => {
                        const { eventType, new: newMedicineData } = payload;

                        setMedicines((prev) => prev.map(med =>
                            med.medicine_id === newMedicineData.id ? {
                                ...med,
                                name: newMedicineData.name,
                                manufacturer: newMedicineData.manufacturer
                            } : med
                        ));
                    }
                )
                .subscribe();

            return () => {
                supabase.removeChannel(stockChannel);
                supabase.removeChannel(medicinesChannel);
            };
        };

        if (pharmacy_id) {
            fetchStock();
        }
    }, [pharmacy_id]);

    const openModal = (type, medicine) => {
        setSelectedMedicine(medicine);
        setModalType(type);
    };

    const closeModal = () => {
        setSelectedMedicine(null);
        setModalType('');
    };

    const handleBuy = async () => {
        const buyQty = parseInt(document.getElementById('buyQty').value, 10);
        if (buyQty && selectedMedicine) {
            const newQuantity = selectedMedicine.quantity + buyQty;
            const { error } = await supabase
                .from('stock')
                .update({ quantity: newQuantity })
                .eq('id', selectedMedicine.id);

            if (error) {
                console.error('Error updating stock quantity:', error.message);
            } else {
                closeModal();
            }
        }
    };

    const handleEdit = async () => {
        const newName = document.getElementById('editName').value;
        const newPrice = parseFloat(document.getElementById('editPrice').value);

        if (newName && selectedMedicine) {
            let newMedicineId = selectedMedicine.medicine_id;

            // If the name has changed, insert the new name into the medicines table
            if (newName !== selectedMedicine.name) {
                const { data, error } = await supabase
                    .from('medicines')
                    .insert([{ name: newName, manufacturer: selectedMedicine.manufacturer }])
                    .select('id');

                if (error) {
                    console.error('Error inserting new medicine:', error.message);
                    return;
                }

                newMedicineId = data[0].id;
            }

            // Update the stock with the new medicine ID and price
            const { error } = await supabase
                .from('stock')
                .update({ medicine_id: newMedicineId, price: newPrice })
                .eq('id', selectedMedicine.id);

            if (error) {
                console.error('Error updating stock:', error.message);
            } else {
                closeModal();
            }
        }
    };

    const handleAddOrder = async () => {
        const orderQty = parseInt(document.getElementById('orderQty').value, 10);
        if (orderQty && selectedMedicine) {
            const { error } = await supabase
                .from('orders')
                .insert([{
                    medicine_id: selectedMedicine.medicine_id,  // Send medicine_id correctly
                    pharmacy_id,  // Use the current pharmacy_id from context
                    order_quantity: orderQty,
                    status: 'Pending'
                }]);

            if (error) {
                console.error('Error adding order:', error.message);
            } else {
                alert(`Order for ${orderQty} units of ${selectedMedicine.name} has been added.`);
                closeModal();
            }
        }
    };

    const handleDelete = async () => {
        if (selectedMedicine) {
            const { error } = await supabase
                .from('stock')
                .delete()
                .eq('id', selectedMedicine.id);

            if (error) {
                console.error('Error deleting stock:', error.message);
            } else {
                closeModal();
            }
        }
    };

    const filteredMedicines = medicines.filter(medicine =>
        medicine.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <input
                type="text"
                placeholder="Search medicines..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: '80%', display: 'block', margin: '0 auto', marginBottom: '20px' }}
            />
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Manufacturer</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredMedicines.map(medicine => (
                        <tr key={medicine.id}>
                            <td>{medicine.name}</td>
                            <td>{medicine.manufacturer}</td>
                            <td>Rs. {medicine.price}</td>
                            <td>{medicine.quantity}</td>
                            <td>
                                <button onClick={() => openModal('buy', medicine)}>Buy</button>
                                <button onClick={() => openModal('edit', medicine)}>Edit</button>
                                <button onClick={() => openModal('order', medicine)}>Add Order</button>
                                <button className="danger" onClick={() => openModal('delete', medicine)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Buy, Edit, Add Order, and Delete actions */}
            {modalType && (
                <div className="modal">
                    <div className="modal-content">
                        {modalType === 'buy' && (
                            <>
                                <h2>Buy {selectedMedicine?.name}</h2>
                                <input id="buyQty" type="number" placeholder="Enter quantity to buy" />
                                <button onClick={handleBuy}>Confirm Purchase</button>
                                <button onClick={closeModal}>Cancel</button>
                            </>
                        )}

                        {modalType === 'edit' && (
                            <>
                                <h2>Edit {selectedMedicine?.name}</h2>
                                <input id="editName" type="text" defaultValue={selectedMedicine?.name} />
                                <input id="editPrice" type="number" defaultValue={selectedMedicine?.price} placeholder="Enter new price" />
                                <button onClick={handleEdit}>Save Changes</button>
                                <button onClick={closeModal}>Cancel</button>
                            </>
                        )}

                        {modalType === 'order' && (
                            <>
                                <h2>Add Order for {selectedMedicine?.name}</h2>
                                <input id="orderQty" type="number" placeholder="Enter quantity to order" />
                                <button onClick={handleAddOrder}>Add Order</button>
                                <button onClick={closeModal}>Cancel</button>
                            </>
                        )}

                        {modalType === 'delete' && (
                            <>
                                <h2>Delete {selectedMedicine?.name}?</h2>
                                <p>Are you sure you want to delete this medicine?</p>
                                <button className="danger" onClick={handleDelete}>Confirm Delete</button>
                                <button onClick={closeModal}>Cancel</button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default StockTable;
