import React, { useState } from 'react';
import styled from 'styled-components';
import { supabase } from '../utils/supabase';
import { useAuth0 } from '@auth0/auth0-react';
import { FaCheck } from 'react-icons/fa'; // Import check icon

const ModalOverlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
`;

const ModalContent = styled.div`
background: white;
padding: 20px;
border-radius: 10px;
width: 400px;
box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
`;

const InputLabel = styled.label`
display: block;
font-size: 14px;
font-weight: bold;
margin-bottom: 5px;
`;

const Input = styled.input`
margin-bottom: 10px;
width: 100%;
padding: 10px;
border: 1px solid #ccc;
border-radius: 5px;
`;

const Button = styled.button`
padding: 10px 15px;
background-color: #28a745 !important;
color: white;
border: none;
border-radius: 5px;
cursor: pointer;
&:hover {
background-color: #218838 !important;
}
`;

const CancelButton = styled(Button)`
background-color: #dc3545 !important;
&:hover {
background-color: #c82333 !important;
}
`;

const DeliveryOptionsLabel = styled.label`
display: block;
font-size: 14px;
font-weight: bold;
margin-bottom: 10px;
`;

const DeliveryOptions = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 10px;
`;

const DeliveryButton = styled.button`
padding: 10px;
width: 48%;
background-color: ${(props) => (props.selected ? '#28a745' : '#f8f9fa')} !important;
color: ${(props) => (props.selected ? 'white' : '#6c757d')} !important;
border: 1px solid #28a745 !important;
border-radius: 5px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;

&:hover {
background-color: ${(props) => (props.selected ? '#218838' : '#e2e6ea')} !important;
color: ${(props) => (props.selected ? 'white' : '#6c757d')} !important;
}

svg {
margin-left: 5px;
}
`;

const ErrorMsg = styled.p`
    background-color: #ffcc00;
color: #333;
padding: 15px;
font-size: 12px;
margin-bottom: 10px;
margin: 20px 0;
border-radius: 5px;
    text-align: center;
    font-size: 1.1em;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;

`;

const AddOrderModal = ({ onClose, hospital, selectedMedicine }) => {
    const [quantity, setQuantity] = useState('');
    const [deliveryOption, setDeliveryOption] = useState('');
    const [description, setDescription] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [errorMsg, setErrorMsg] = useState(''); // State for error messages
    const { isAuthenticated, user } = useAuth0(); // Auth0 for authentication

    const handleOrderSubmit = async () => {
        // Check if the user is authenticated
        if (!isAuthenticated) {
            setErrorMsg('Please log in to place an order online.');
            return;
        }

        // Check if all fields are filled in
        if (!quantity || !contactNumber || !deliveryOption) {
            setErrorMsg('Please fill in all required fields.');
            return;
        }

        try {
            const { sub: customer_id, name: customer_name } = user;
            const { error } = await supabase
                .from('customer_orders')
                .insert([
                    {
                        medicine: selectedMedicine,
                        quantity,
                        delivery_type: deliveryOption,
                        description: description || null,
                        pharmacy_name: hospital.name,
                        order_status: 'pending',
                        customer_id,
                        customer_name,
                        customer_tele: contactNumber,
                    },
                ]);

            if (error) {
                console.error('Error inserting order:', error.message);
            } else {
                alert('Order placed successfully!');
                onClose();
            }
        } catch (error) {
            console.error('Error placing order:', error);
        }
    };

    return (
        <ModalOverlay>
            <ModalContent>
                <h2>Order {selectedMedicine}</h2>

                {/* Display error overlay if there is an error message */}
                {isAuthenticated ? (
                    <>
                        {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
                        
                        <InputLabel htmlFor="quantity">Quantity</InputLabel>
                        <Input id="quantity" type="number" placeholder="Enter Quantity" value={quantity} onChange={(e) =>
                            setQuantity(e.target.value)}
                        />

                        <InputLabel htmlFor="contactNumber">Contact Number</InputLabel>
                        <Input id="contactNumber" type="text" placeholder="Enter Contact Number" value={contactNumber} onChange={(e) =>
                            setContactNumber(e.target.value)}
                        />

                        <DeliveryOptionsLabel>Select Delivery Method:</DeliveryOptionsLabel>
                        <DeliveryOptions>
                            <DeliveryButton selected={deliveryOption === 'delivery'} onClick={() => setDeliveryOption('delivery')}
                            >
                                🚚 Delivery {deliveryOption === 'delivery' &&
                                    <FaCheck />}
                            </DeliveryButton>
                            <DeliveryButton selected={deliveryOption === 'pickup'} onClick={() => setDeliveryOption('pickup')}
                            >
                                🏬 Pick up {deliveryOption === 'pickup' &&
                                    <FaCheck />}
                            </DeliveryButton>
                        </DeliveryOptions>

                        <InputLabel htmlFor="description">Additional Notes (Optional)</InputLabel>
                        <Input id="description" type="text" placeholder="Additional Notes (Optional)" value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />

                        <Button onClick={handleOrderSubmit}>Place Order</Button>
                    </>
                ) : (<>
                    <>
                            <ErrorMsg> Please Log into Order Medicines Online. </ErrorMsg>
                    </>
                </>)
                }
                <CancelButton onClick={onClose}>Cancel</CancelButton>
            </ModalContent>
        </ModalOverlay>
    );
};

export default AddOrderModal;
