import React, { useEffect, useState, useContext } from 'react';
import { supabase } from '../../utils/supabase'; // Import the supabase client
import PharmacyContext from '../../Components/Pos/PharmacyContext'; // Adjust the import based on your setup

export default function AllMedicines() {
    const [medicines, setMedicines] = useState([]);
    const [selectedMedicine, setSelectedMedicine] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderQty, setOrderQty] = useState(0);
    const pharmacyContext = useContext(PharmacyContext);
    const pharmacyId = pharmacyContext ? pharmacyContext.id : 1; // Use context to get the pharmacy ID

    useEffect(() => {
        const fetchMedicines = async () => {
            // Fetch medicines and their stock availability
            const { data: medicinesData, error: medicinesError } = await supabase
                .from('medicines')
                .select('id, name, manufacturer');

            if (medicinesError) {
                console.error('Error fetching medicines:', medicinesError.message);
                return;
            }

            const stockPromises = medicinesData.map(async (medicine) => {
                const { data: stockData, error: stockError } = await supabase
                    .from('stock')
                    .select('quantity')
                    .eq('medicine_id', medicine.id)
                    .eq('pharmacy_id', pharmacyId)
                    .single();

                if (stockError) {
                    console.error(`Error fetching stock for medicine ${medicine.name}:`, stockError.message);
                    return { ...medicine, availability: 'No Stock' };
                } else {
                    const availability = stockData?.quantity > 0
                        ? `Available (${stockData.quantity})`
                        : 'No Stock';
                    return { ...medicine, availability };
                }
            });

            const updatedMedicines = await Promise.all(stockPromises);
            setMedicines(updatedMedicines);
        };

        fetchMedicines();

        // Subscribe to changes in stock and medicines tables
        const stockSubscription = supabase
            .channel('stock_channel')
            .on('postgres_changes', { event: '*', schema: 'public', table: 'stock' }, () => {
                fetchMedicines(); // Refetch data on any change
            })
            .subscribe();

        const medicineSubscription = supabase
            .channel('medicine_channel')
            .on('postgres_changes', { event: '*', schema: 'public', table: 'medicines' }, () => {
                fetchMedicines(); // Refetch data on any change
            })
            .subscribe();

        return () => {
            supabase.removeChannel(stockSubscription);
            supabase.removeChannel(medicineSubscription);
        };
    }, [pharmacyId]);

    const openModal = (medicine) => {
        setSelectedMedicine(medicine);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedMedicine(null);
        setIsModalOpen(false);
        setOrderQty(0);
    };

    const handleAddOrder = async () => {
        if (orderQty && selectedMedicine) {
            const { error } = await supabase
                .from('orders')
                .insert([{
                    medicine_id: selectedMedicine.id,
                    pharmacy_id: pharmacyId,
                    order_quantity: orderQty,
                    status: 'Pending'
                }]);

            if (error) {
                console.error('Error adding order:', error.message);
            } else {
                alert(`Order for ${orderQty} units of ${selectedMedicine.name} has been added.`);
                closeModal(); // Close modal after order is added
            }
        } else {
            alert("Please enter a valid quantity");
        }
    };

    return (
        <div className="pos_container">
            <h1>All Medicines</h1>
            <table>
                <thead>
                    <tr>
                        <th>Medicine Name</th>
                        <th>Manufacturer</th>
                        <th>Availability</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {medicines.map(medicine => (
                        <tr key={medicine.id}>
                            <td>{medicine.name}</td>
                            <td>{medicine.manufacturer}</td>
                            <td>{medicine.availability}</td>
                            <td> <button onClick={() => openModal(medicine)}>Order</button> </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for adding order */}
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Add Order for {selectedMedicine?.name}</h2>
                        <input
                            id="orderQty"
                            type="number"
                            placeholder="Enter quantity to order"
                            value={orderQty}
                            onChange={(e) => setOrderQty(e.target.value)}
                        />
                        <button onClick={handleAddOrder}>Add Order</button>
                        <button onClick={closeModal}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
}
